import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {BottomSheetMenuMainMobileComponent} from '../bottom-sheet/bottom-sheet-menu-main-mobile/bottom-sheet-menu-main-mobile.component';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {

  constructor( private bottomSheet: MatBottomSheet ) { }

  menuMobile(): void {
    this.bottomSheet.open(BottomSheetMenuMainMobileComponent, {
      panelClass: ['bottom-sheet-mobile-menu']
    });
  }
}
