<form #update="ngForm">
  <div class="container-fluid mt-3">
    <div class="row justify-content-center">
      <div class="col-3 align-self-center">
        <h4 class="mb-0">Edycja</h4>
      </div>
      <div class="col-9 align-self-center text-right">
        <button mat-raised-button class="btn-success rounded-0" (click)="onSaveChanges(update)">Zapisz zmiany</button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <ng-container *ngIf="itemDetails | async as category">

    <!-- hide input id start -->
    <mat-form-field appearance="fill" color="accent" class="w-100 d-none">
      <mat-label>id</mat-label>
      <input name="id" matInput [(ngModel)]="category.id">
    </mat-form-field>
    <!-- hide input id end -->

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-3 align-self-center text-center">

          <ng-container *ngIf="imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block" [ngStyle]="{'background-image': 'url('+ imageSrc + ')' }"></div>
          </ng-container>

          <ng-container *ngIf="!imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block" *ngIf="category.iconCategory" [ngStyle]="{'background-image': 'url('+ media + category.iconCategory + ')' }"></div>
            <div class="text-center" *ngIf="!category.iconCategory">
              <span class="material-icons display-3 mb-0 text-white-50">image_not_supported</span>
            </div>
          </ng-container>

          <div [formGroup]="myForm">
            <button mat-raised-button>
              <input
                formControlName="file"
                id="file"
                type="file"
                class="file-icon"
                (change)="onFileChange($event)">
            </button>
            <button class="mt-3" mat-raised-button color="primary" type="submit" (click)="onSendFile()">Wyślij na serwer</button>
          </div>

        </div>
        <div class="col-9">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Nazwa kategorii</mat-label>
            <textarea name="nameCategory" matInput rows="7" [(ngModel)]="category.nameCategory"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

  </ng-container>

</form>
