<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-3 mb-3">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="h3 fw-normal mb-3">Jak wybrać fajerwerki</div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div class="container-xxl">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="h3 pt-4 text-center">
        To jedno z najczęściej zadawanych pytań, dlatego przygotowaliśmy dla Państwa film, <br/> który przybliży Państwu magiczny świat pirotechniki i pomoże we właściwych wyborach.
      </div>
      <div class="pt-4">
        <youtube-player
          videoId="SZ5DSCx3POs"
          suggestedQuality="highres"
          [height]="465"
          [width]="'100%'"
        ></youtube-player>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
<app-basic-menu></app-basic-menu>
