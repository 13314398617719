import {Component, Inject, OnInit} from '@angular/core';
import {BackEndApiService} from '../../dashboard/service/back-end-api.service';
import {GlobalService} from '../../global/global.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../../dashboard/service/interface';
import {UrlService} from '../../global/url.service';

@Component({
  selector: 'app-dialog-add-products-top-shows',
  templateUrl: './dialog-add-products-top-shows.component.html',
  styleUrls: ['./dialog-add-products-top-shows.component.css']
})
export class DialogAddProductsTopShowsComponent implements OnInit {
  displayProducts: any;
  media = this.url.Media;
  constructor(
    private global: GlobalService,
    private api: BackEndApiService,
    private url: UrlService,
    public dialogRef: MatDialogRef<DialogAddProductsTopShowsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.api.getProducts().subscribe(props => {
      if (props.length > 0) {
        this.displayProducts = props;
      }
    });
  }

  addProducts(id: string): void {
    this.data.dialog_products.push(id);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
