<div class="body-login bg-dark">
  <main class="form-signin">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-5">
          <form class="w-100">
            <div class="mat-headline text-white">Logowanie</div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100" color="warn" appearance="fill">
                  <mat-label>Login</mat-label>
                  <input [(ngModel)]="username" class="text-white-50" name="username" matInput autocomplete="off">
                  <mat-icon matSuffix color="accent">sentiment_very_satisfied</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field class="w-100" color="warn" appearance="fill">
                  <mat-label>Hasło</mat-label>
                  <input [(ngModel)]="password" class="text-white-50" type="password" name="password" matInput autocomplete="on">
                  <mat-icon matSuffix color="accent">security</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button (click)="login()" class="w-100" type="submit" mat-raised-button color="accent">Zaloguj się</button>
                <p class="mt-5 mb-3 text-muted">inforpol.net | Mariusz Zakrzewski &copy;  2020</p>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </main>
</div>
<router-outlet></router-outlet>
