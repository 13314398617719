import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet-menu-main-mobile',
  templateUrl: './bottom-sheet-menu-main-mobile.component.html',
  styleUrls: ['./bottom-sheet-menu-main-mobile.component.css']
})
export class BottomSheetMenuMainMobileComponent implements OnInit {

  constructor(
    private route: Router,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetMenuMainMobileComponent>
  ) { }

  ngOnInit(): void {
  }

  link1(): void {
    this.route.navigateByUrl('instrukcja-korzystania-z-aplikacji');
    this.bottomSheetRef.dismiss();
  }
  link2(): void {
    this.route.navigateByUrl('jak-wybrac-fajerwerki');
    this.bottomSheetRef.dismiss();
  }
  link3(): void {
    this.route.navigateByUrl('propozycje-pokazow-piromax');
    this.bottomSheetRef.dismiss();
  }
  link4(): void {
    this.route.navigateByUrl('tworzenie-pokazu');
    this.bottomSheetRef.dismiss();
  }
  link5(): void {
    this.route.navigateByUrl('propozycje-pokazow-piromax');
    this.bottomSheetRef.dismiss();
  }

}
