<div class="text-center mb-3">
  <span class="material-icons display-2">save_alt</span>
</div>
<div class="text-center">
  <h1 mat-dialog-title>Trwa pobieranie pliku</h1>
  <div mat-dialog-actions>
    <button class="mx-auto" mat-button mat-dialog-close mat-raised-button color="primary" (click)="close()">Zamknij</button>
  </div>
</div>

