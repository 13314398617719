<ng-container *ngIf="itemDetails | async as clients">

  <div class="container-fluid mt-3">
    <div class="row justify-content-center">
      <div class="col text-left align-self-center">
        <h1 class="mb-0">Email: {{clients.email}}</h1>
        <h4 class="text-white-50">Data: {{clients.currentData}}</h4>
      </div>
      <div class="col text-left align-self-center text-right">
        <h1 class="mb-0">Cena: {{clients.Price}} zł</h1>
        <h4 class="text-white-50">Czas trwania pokazu: {{clients.Time}}</h4>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3 mb-3">
    <div class="row">
      <div class="col-2" *ngFor="let product of clientProducts">
        <mat-card class="pl-0 pr-0 pb-0">
          <div class="card border-0 bg-transparent p-0">

            <div class="mt-2 mb-2">
              <ng-container *ngIf="!product.iconProduct">
                <span class="material-icons display-2 mb-0 text-white-50">image_not_supported</span>
              </ng-container>
              <ng-container *ngIf="product.iconProduct">
                <div class="backgroundImageListShows mt-2" [ngStyle]="{'background-image': 'url(' + media + product.iconProduct + ')' }"></div>
              </ng-container>
            </div>

            <div class="card-body p-0">

              <table class="table mt-3 table-striped table-dark mb-0">
                <tbody>
                <tr>
                  <td>Model:</td>
                  <td>
                    <ng-container *ngIf="product.modelProduct">
                     {{product.modelProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.modelProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Index:</td>
                  <td>
                    <ng-container *ngIf="product.indexProduct">
                      {{product.indexProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.indexProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>YouTube:</td>
                  <td>
                    <ng-container *ngIf="product.videoYoutube">
                      <a class="text-white-50" href="https://www.youtube.com/watch?v={{product.videoYoutube}}" target="_blank">{{product.videoYoutube}}</a>
                    </ng-container>
                    <ng-container *ngIf="!product.videoYoutube">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Cena:</td>
                  <td>
                    <ng-container *ngIf="product.priceProduct">
                      {{product.priceProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.priceProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Czas:</td>
                  <td>
                    <ng-container *ngIf="product.timeVideoAll">
                      {{product.timeVideoAll}}
                    </ng-container>
                    <ng-container *ngIf="!product.timeVideoAll">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Liczba strzałów:</td>
                  <td>
                    <ng-container *ngIf="product.shootsProduct">
                      {{product.shootsProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.shootsProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Kaliber:</td>
                  <td>
                    <ng-container *ngIf="product.caliberProduct">
                      {{product.caliberProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.caliberProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>Wymiary:</td>
                  <td>
                    <ng-container *ngIf="product.dimensionsProduct">
                      {{product.dimensionsProduct}}
                    </ng-container>
                    <ng-container *ngIf="!product.dimensionsProduct">
                      -
                    </ng-container>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </mat-card>
      </div>
    </div>
  </div>

</ng-container>
