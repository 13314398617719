<table class="table table-dark table-striped bg-transparent" mat-table [dataSource]="categories">

  <ng-container matColumnDef="name">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Nazwa kategorii</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let category">
      <ng-container *ngIf="category.nameCategory">
        {{category.nameCategory}}
      </ng-container>
      <ng-container *ngIf="!category.nameCategory">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th class="border-0 text-right align-middle" mat-header-cell *matHeaderCellDef>Akcje</th>
    <td class="border-0 text-right align-middle" mat-cell *matCellDef="let category">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <button
            routerLink="/dashboard/panel/edit-categories/{{category.id}}"
            mat-icon-button class="text-success">
            <span class="material-icons">edit</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button
            (click)="delete(category.id)"
            mat-icon-button class="text-danger">
            <span class="material-icons">delete</span>
          </button>
        </li>
      </ul>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
