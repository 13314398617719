import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  DialogDataUrl,
  SubpageSuggestionShowsComponent
} from '../../front/pages/subpage-suggestion-shows/subpage-suggestion-shows.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dialog-refresh-player-with-parametr-url',
  templateUrl: './dialog-refresh-player-with-parametr-url.component.html',
  styleUrls: ['./dialog-refresh-player-with-parametr-url.component.css']
})
export class DialogRefreshPlayerWithParametrUrlComponent implements OnInit {

  constructor(
    private route: Router,
    public dialogRefUrl: MatDialogRef<SubpageSuggestionShowsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataUrl
  ) { }

  ngOnInit(): void {
    this.route.navigateByUrl('/propozycje-pokazow-piromax/' + this.data.url);
    setTimeout(() => {
      window.location.reload();
      this.dialogRefUrl.close();
    }, 100);
  }

}
