import {Component, Inject, OnInit} from '@angular/core';
import {
  DialogDataPopUpProduct,
  SubpageSuggestionShowsComponent
} from '../../front/pages/subpage-suggestion-shows/subpage-suggestion-shows.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FrontEndApiService} from '../../front/service/front-end-api.service';

@Component({
  selector: 'app-dialog-pop-up-product',
  templateUrl: './dialog-pop-up-product.component.html',
  styleUrls: ['./dialog-pop-up-product.component.css']
})
export class DialogPopUpProductComponent implements OnInit {

  displayProduct = [];
  loader = true;
  hideButtonAddToCart: boolean;

  constructor(
    private api: FrontEndApiService,
    public dialogRef: MatDialogRef<SubpageSuggestionShowsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopUpProduct
  ) { }

  ngOnInit(): void {
    this.hideButtonAddToCart = this.data.showButtonAddToCart;
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    this.api.getProduct(this.data.idProduct).subscribe(props => {
      if (props) {
        this.loader = false;
        this.displayProduct.push(props);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  onAddToCart(id: any): void {
    this.dialogRef.close({addToCart: id});
  }

}
