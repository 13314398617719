import { Component, OnInit } from '@angular/core';
import {FrontEndApiService} from '../../../service/front-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogPopUpProductComponent} from '../../../../dialog/dialog-pop-up-product/dialog-pop-up-product.component';
import {UrlService} from '../../../../global/url.service';
import {Options} from '@angular-slider/ngx-slider';

export interface DialogDataPopUpProduct {
  idProduct: string;
  showButtonAddToCart: boolean;
}

@Component({
  selector: 'app-search-advanced',
  templateUrl: './search-advanced.component.html',
  styleUrls: ['./search-advanced.component.css']
})
export class SearchAdvancedComponent implements OnInit {

  itemProducts = [];
  idProduct: string;
  media = this.url.Media;
  cacheTime: any[] = [];
  cacheDisplayTime;
  cacheProducts: any[] = [];
  cacheDisplayPrice;
  dialogButtonAddToCart;
  sumTime: number = null;
  sumPrice: number = null;

  sliderRangeHide = false;
  arrayCaliber = [];
  arrayTime = [];
  arrayShoots = [];
  arrayPrice = [];
  minCaliber;
  maxCaliber;
  minTime;
  maxTime;
  minShoots;
  maxShoots;
  minPrice;
  maxPrice;

  optionsCaliber: Options;
  optionsTime: Options;
  optionsShoots: Options;
  optionsPrice: Options;

  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    public dialog: MatDialog,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    if (JSON.parse(window.localStorage.getItem('product'))) {
      this.cacheProducts = JSON.parse(window.localStorage.getItem('product'));
    }
    if (window.localStorage.getItem('time')) {
      this.sumTime = parseInt(window.localStorage.getItem('time'), 10);
      this.cacheDisplayTime = this.global.secondsToTime(window.localStorage.getItem('time'));
    }
    if (window.localStorage.getItem('price')) {
      this.sumPrice = parseInt(window.localStorage.getItem('price'), 10);
      this.cacheDisplayPrice = parseInt(window.localStorage.getItem('price'), 10);
    }
    this.api.getProducts().subscribe(propsProducts => {
      this.itemProducts = propsProducts;
      propsProducts.forEach((index) => {
        if (index.priceProduct && index.caliberProduct && index.shootsProduct && index.timeVideoAll) {
          this.arrayCaliber.push(parseInt(index.caliberProduct, 10));
          this.arrayPrice.push(parseInt(index.priceProduct, 10));
          this.arrayShoots.push(parseInt(index.shootsProduct, 10));
          this.arrayTime.push(parseInt(index.timeVideoAll, 10));
        }
      });
      this.global.loaderImage();
      if (propsProducts.length > 0) {
        this.global.hideDialog();
        this.minCaliber = Math.min.apply(Math, this.arrayCaliber);
        this.maxCaliber = Math.max.apply(Math, this.arrayCaliber);
        this.minPrice = Math.min.apply(Math, this.arrayPrice);
        this.maxPrice = Math.max.apply(Math, this.arrayPrice);
        this.minShoots = Math.min.apply(Math, this.arrayShoots);
        this.maxShoots = Math.max.apply(Math, this.arrayShoots);
        this.minTime = Math.min.apply(Math, this.arrayTime);
        this.maxTime = Math.max.apply(Math, this.arrayTime);
        setTimeout(() => {
          this.sliderRangeHide = true;
          this.optionsCaliber = { floor: this.minCaliber, ceil: this.maxCaliber };
          this.optionsTime = { floor: this.minTime,  ceil: this.maxTime  };
          this.optionsShoots = { floor: this.minShoots, ceil: this.maxShoots  };
          this.optionsPrice = { floor: this.minPrice,  ceil: this.maxPrice };
        }, 200);
      }
    });
  }

  onPopUpProduct(id: any): void {
    const dialogRef = this.dialog.open(DialogPopUpProductComponent, {
      data: {idProduct: id, showButtonAddToCart: true}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.idProduct = result;
      if (result.addToCart !== undefined) {
        // from dialog pop up product
        this.onAddToCart(result.addToCart);
      }
    });
  }

  onAddToCart(id: any): void {
    let createProductsArray = [];
    this.api.getProduct(id).subscribe(props => {
      this.global.dialogSuccessAddToCart();
      this.cacheTime.push(props);
      createProductsArray = this.cacheTime.map((ele) => ({
        id: ele.id,
        timeAll: parseInt(ele.timeVideoAll, 10),
        youTube: ele.videoYoutube,
        start: ele.timeVideoStart,
        end: ele.timeVideoEnd,
        price: parseInt(ele.priceProduct, 10),
        shoots: ele.shootsProduct,
        icon: ele.iconProduct
      }));
      const arrayTime = [];
      createProductsArray.forEach(time => {
        arrayTime.push(time.timeAll);
      });
      let sumTime: number = null;
      arrayTime.forEach((value) => {
        sumTime += value;
      });
      const arrayPrice = [];
      createProductsArray.forEach(priceProducts => {
        arrayPrice.push(priceProducts.price);
      });
      let sumPrice: number = null;
      arrayPrice.forEach((value) => {
        sumPrice += value;
      });
      const resultTime = this.sumTime + sumTime;
      const resultPrice = this.sumPrice + sumPrice;
      localStorage.setItem('time', JSON.stringify(resultTime));
      localStorage.setItem('price', JSON.stringify(resultPrice));
      this.cacheDisplayTime = this.global.secondsToTime(window.localStorage.getItem('time'));
      this.cacheDisplayPrice = parseInt(window.localStorage.getItem('price'), 10);

      if (JSON.parse(window.localStorage.getItem('product'))) {
        this.cacheProducts = JSON.parse(window.localStorage.getItem('product'));
      }
      this.cacheProducts.push(id);
      localStorage.setItem('product', JSON.stringify(this.cacheProducts));
    });
  }

  sortPrice(): void {
    this.itemProducts.sort((a, b) => parseFloat(a.priceProduct) - parseFloat(b.priceProduct));
  }
  sortTime(): void {
    this.itemProducts.sort((a, b) => parseFloat(a.timeVideoAll) - parseFloat(b.timeVideoAll));
  }
  sortShoots(): void {
    this.itemProducts.sort((a, b) => parseFloat(a.shootsProduct) - parseFloat(b.shootsProduct));
  }
  sortCaliber(): void {
    this.itemProducts.sort((a, b) => parseFloat(a.caliberProduct) - parseFloat(b.caliberProduct));
  }

}
