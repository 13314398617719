import { Component, OnInit } from '@angular/core';
import {BackEndApiService} from '../../../service/back-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {MatTableDataSource} from '@angular/material/table';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-list-shows',
  templateUrl: './list-shows.component.html',
  styleUrls: ['./list-shows.component.css']
})
export class ListShowsComponent implements OnInit {

  shows;
  columns: string[] = [ 'icon', 'name', 'listProduct', 'action' ];
  media = this.url.Media;

  constructor(
    private api: BackEndApiService,
    private global: GlobalService,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.api.getShows().subscribe(props => {
      this.shows = new MatTableDataSource(props);
      this.shows.data = props;

      if (props.length > 0) {
        this.global.hideDialog();
      }

      if (props.length === 0) {
        this.global.dialogInfoEmptyList();
      }

    });
  }

  delete(id: number | string): void {
    this.api.deleteShow(id).subscribe(props => {
      console.log(props);
      this.shows.data = this.shows.data.filter(item => item.id !== id);
      this.global.dialogDeleteItem();
    });
  }

}
