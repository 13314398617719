<form #update="ngForm">
  <div class="container-fluid mt-3">
    <div class="row justify-content-center">
      <div class="col-3 align-self-center">
        <h4 class="mb-0">Edycja</h4>
      </div>
      <div class="col-9 align-self-center text-right">
        <button mat-raised-button class="btn-success rounded-0" (click)="onSaveChanges(update)">Zapisz zmiany</button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <ng-container *ngIf="itemDetails | async as shows">

    <!-- hide input id start -->
    <mat-form-field appearance="fill" color="accent" class="w-100 d-none">
      <mat-label>id</mat-label>
      <input name="id" matInput [(ngModel)]="shows.id">
    </mat-form-field>
    <!-- hide input id end -->

    <div class="container-fluid">

      <div class="row justify-content-center mb-3">
        <div class="col-12">
          <mat-slide-toggle color="primary" name="showTitle" [(ngModel)]="shows.showTitle">
            Ukryj nazwę pokazu ?
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-3 align-self-center text-center">
          <ng-container *ngIf="imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block" [ngStyle]="{'background-image': 'url('+ imageSrc + ')' }"></div>
          </ng-container>

          <ng-container *ngIf="!imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block" *ngIf="shows.showIcon" [ngStyle]="{'background-image': 'url('+ media + shows.showIcon + ')' }"></div>
            <div class="text-center" *ngIf="!shows.showIcon">
              <span class="material-icons display-3 mb-0 text-white-50">image_not_supported</span>
            </div>
          </ng-container>

          <div [formGroup]="myForm">
            <button mat-raised-button>
              <input
                formControlName="file"
                id="file"
                type="file"
                class="file-icon"
                (change)="onFileChange($event)">
            </button>
            <button class="mt-3" mat-raised-button color="primary" type="submit" (click)="onSendFile()">Wyślij na serwer</button>
          </div>
        </div>
        <div class="col-9">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Nazwa pokazu</mat-label>
            <textarea name="showName" matInput rows="5" [(ngModel)]="shows.showName"></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Dodatkowy opis</mat-label>
            <textarea name="showDescription" matInput rows="5" [(ngModel)]="shows.showDescription"></textarea>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div class="container-fluid mt-4 mb-3">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="alert alert-info">
            <p class="mb-0">Wybierz produkty aby utworzyć pokaz</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mb-3">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <button mat-raised-button color="primary" (click)="openDialogProducts()"><mat-icon>add</mat-icon> Produkty</button>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid mt-3 container-fluid-chosen-product-from-edit-shows">
      <div class="row justify-content-center" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)">

        <ng-container *ngIf="products">

          <ng-container *ngFor="let chosenProduct of products; let i = index">
            <ng-container *ngFor="let productDB of allProductsFromDB">
              <ng-container *ngIf="chosenProduct === productDB.id">
                <div class="col-12 mb-3" cdkDrag>
                  <mat-card>
                    <div class="card bg-transparent mb-3">
                      <ng-container *ngIf="!productDB.videoYoutube">
                        <div class="row p-0">
                          <div class="col-12">
                            <div class="alert alert-danger p-1">
                              Brak załączonego video
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <div class="row g-0">
                        <div class="col-1 align-self-center">
                          <ng-container *ngIf="!productDB.iconProduct">
                            <span class="material-icons display-2 mb-0 text-white-50">image_not_supported</span>
                          </ng-container>
                          <ng-container *ngIf="productDB.iconProduct">
                            <img alt="" src="{{media + productDB.iconProduct}}" class="img-fluid mx-auto d-block"/>
                          </ng-container>
                        </div>
                        <div class="col-11">
                          <div class="card-body">
                            <h5 class="card-title">
                              <ng-container *ngIf="productDB.modelProduct">
                                Model: {{productDB.modelProduct}}
                              </ng-container>
                              <ng-container *ngIf="!productDB.modelProduct">
                                Model: -
                              </ng-container>
                            </h5>
                            <h5 class="card-title">
                              <ng-container *ngIf="productDB.indexProduct">
                                Index: {{productDB.indexProduct}}
                              </ng-container>
                              <ng-container *ngIf="!productDB.indexProduct">
                                Index: -
                              </ng-container>
                            </h5>
                            <h5 class="card-title">
                              <ng-container *ngIf="productDB.videoYoutube">
                                Video YouTube: {{productDB.videoYoutube}}
                              </ng-container>
                              <ng-container *ngIf="!productDB.videoYoutube">
                                Video YouTube: -
                              </ng-container>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">
                      <button mat-flat-button color="primary" class="mr-2">
                        <span class="material-icons">import_export</span>
                      </button>
                      <button mat-flat-button color="accent" (click)="delete(i)">
                        <span class="material-icons">delete_forever</span>
                      </button>
                    </div>
                  </mat-card>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>



      </div>
    </div>

  </ng-container>
</form>
