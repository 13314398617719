import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../guards/authentication.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../global/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;

  constructor(
    private auth: AuthenticationService,
    private global: GlobalService,
    private router: Router
  ) {
    if (this.auth.loggedIn) {
      this.router.navigate(['dashboard/panel']);
    }
  }

  login(): void {
    if (this.username !== '' && this.password !== '') {
      if (this.auth.login(this.username, this.password)) {
        this.router.navigate(['dashboard/panel']);
      }
      else {
        this.global.dialogErrorLogin();
      }
    }
  }

  ngOnInit(): void {
  }

}
