import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ICategory, IClient, IProducts, IShows} from './interface';
import {UrlService} from '../../global/url.service';

@Injectable({
  providedIn: 'root'
})
export class BackEndApiService {

  constructor(
    private http: HttpClient,
    private url: UrlService
  ) { }

  getProducts(): Observable<Array<IProducts>> {
    return this.http.get<Array<IProducts>>(this.url.Api + 'products.json');
  }
  addProduct(product: IProducts): any {
    return this.http.post<Array<IProducts>>(this.url.Api + 'products.json', product);
  }
  updateProduct(product: IProducts): Observable<Array<IProducts>> {
    return this.http.put<Array<IProducts>>(this.url.Api + 'products/' + product.id + '.json', product);
  }
  getProduct(id: string | number): Observable<Array<IProducts>> {
    return this.http.get<Array<IProducts>>(this.url.Api + 'products/' + id + '.json');
  }
  deleteProduct(id: string | number): Observable<Array<IProducts>> {
    return this.http.delete<Array<IProducts>>(this.url.Api + 'products/' + id + '.json');
  }
  getCategories(): Observable<Array<ICategory>> {
    return this.http.get<Array<ICategory>>(this.url.Api + 'categories.json');
  }
  addCategory(category: ICategory): any {
    return this.http.post<Array<ICategory>>(this.url.Api + 'categories.json', category);
  }
  updateCategory(category: ICategory): Observable<Array<ICategory>> {
    return this.http.put<Array<ICategory>>(this.url.Api + 'categories/' + category.id + '.json', category);
  }
  getCategory(id: string | number): Observable<Array<ICategory>> {
    return this.http.get<Array<ICategory>>(this.url.Api + 'categories/' + id + '.json');
  }
  deleteCategory(id: string | number): Observable<Array<ICategory>> {
    return this.http.delete<Array<ICategory>>(this.url.Api + 'categories/' + id + '.json');
  }
  getShows(): Observable<Array<IShows>> {
    return this.http.get<Array<IShows>>(this.url.Api + 'shows.json');
  }
  addShow(show: IShows): any {
    return this.http.post<Array<IShows>>(this.url.Api + 'shows.json', show);
  }
  updateShow(show: IShows): Observable<Array<IShows>> {
    return this.http.put<Array<IShows>>(this.url.Api + 'shows/' + show.id + '.json', show);
  }
  getShow(id: string | number): Observable<Array<IShows>> {
    return this.http.get<Array<IShows>>(this.url.Api + 'shows/' + id + '.json');
  }
  deleteShow(id: string | number): Observable<Array<IShows>> {
    return this.http.delete<Array<IShows>>(this.url.Api + 'shows/' + id + '.json');
  }
  getClients(): Observable<Array<IClient>> {
    return this.http.get<Array<IClient>>(this.url.Api + 'clients.json');
  }
  getClient(id: string | number): Observable<Array<IClient>> {
    return this.http.get<Array<IClient>>(this.url.Api + 'clients/' + id + '.json');
  }
  deleteClient(id: string | number): Observable<Array<IClient>> {
    return this.http.delete<Array<IClient>>(this.url.Api + 'clients/' + id + '.json');
  }
}
