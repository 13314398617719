<div class="container-xxl mt-0 mb-0 mt-lg-2 mb-lg-3">
  <div class="row justify-content-center">
    <div class="col-2 align-self-center">
      <div class="d-none d-lg-block">
        <img alt="Piromax - Producent petard i fajerwerków" src="assets/img/front/piromax.png" class="img-fluid" routerLink="/home">
      </div>
    </div>
    <div class="col-10 text-right align-self-center">
      <div class="d-none d-lg-block">
        <div class="h1 mb-0 text-uppercase fw-light">Witaj w piromax</div>
        <div class="h5 text-white-50 mb-0 fw-light">Kreator pokazów pirotechnicznych</div>
      </div>
    </div>
  </div>
</div>
<!-- mobile start -->
<div class="container-xxl mt-1 mb-1 mt-lg-0 mb-lg-0">
  <div class="row justify-content-center">
    <div class="col-2 align-self-center">
      <div class="d-block d-lg-none">
        <button mat-icon-button (click)="onMenuMobile()">
          <span class="material-icons icon-menu-mobile">menu</span>
        </button>
      </div>
    </div>
    <div class="col-7 align-self-center text-center">
      <div class="d-block d-lg-none">
        <div class="h4 title-creator-of-pyrotechnic-shows text-uppercase mb-0 fw-normal">Kreator pokazów pirotechnicznych piromax</div>
      </div>
    </div>
    <div class="col-3 align-self-center">
      <div class="d-block d-lg-none">
        <img alt="Piromax - Producent petard i fajerwerków" src="assets/img/front/piromax.png" class="img-fluid logo-mobile" routerLink="/home">
      </div>
    </div>
  </div>
</div>
<!-- mobile end -->
<div class="container-xxl position-relative">
  <div class="row">
    <div class="col-12">
      <hr/>
    </div>
  </div>
</div>
<div class="container-xxl mt-0 mb-0 mt-lg-2 mb-lg-3">
  <div class="row justify-content-center">
    <div class="col d-flex align-items-stretch pr-0">
      <button class="btn btn-dark pb-1 pt-1 btn-menu text-uppercase rounded-pill w-100 d-none d-lg-block" routerLink="/home" mat-ripple>
        Strona główna
      </button>
    </div>
    <div class="col d-flex align-items-stretch pr-0">
      <button class="btn btn-dark btn-menu text-uppercase rounded-pill w-100 d-none d-lg-block" routerLink="/instrukcja-korzystania-z-aplikacji" mat-ripple>
        Instrukcja korzystania <br/> z aplikacji
      </button>
    </div>
    <div class="col d-flex align-items-stretch pr-0">
      <button class="btn btn-dark btn-menu text-uppercase rounded-pill w-100 d-none d-lg-block" routerLink="/jak-wybrac-fajerwerki" mat-ripple>
        Jak wybrać fajerwerki
      </button>
    </div>
    <div class="col d-flex align-items-stretch pr-0">
      <button class="btn btn-danger btn-menu-red text-uppercase rounded-pill w-100 d-none d-lg-block" routerLink="/tworzenie-pokazu" mat-ripple>
        Nowy pokaz
      </button>
    </div>
    <div class="col d-flex align-items-stretch">
      <button class="btn btn-dark btn-menu text-uppercase rounded-pill w-100 d-none d-lg-block" routerLink="/propozycje-pokazow-piromax" mat-ripple>
        Propozycje piromax
      </button>
    </div>
  </div>
</div>
