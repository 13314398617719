import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICategory } from '../../../service/interface';
import { GlobalService } from '../../../../global/global.service';
import { BackEndApiService } from '../../../service/back-end-api.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-edit-categories',
  templateUrl: './edit-categories.component.html',
  styleUrls: ['./edit-categories.component.css']
})
export class EditCategoriesComponent implements OnInit {

  itemDetails: Observable<Array<ICategory>>;
  media = this.url.Media;

  /** upload file */
  /** add module ReactiveFormsModule */
  /** add in constructor private httpUploadFile: HttpClient */
  imageSrc: any;
  myForm: any;

  constructor(
    private global: GlobalService,
    private api: BackEndApiService,
    private route: ActivatedRoute,
    private httpUploadFile: HttpClient,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.itemDetails = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.api.getCategory(params.get('id')))
    );
    this.itemDetails.subscribe(props => {
      if (props) {
        const stringifyItemDetails = JSON.stringify(props);
        const parseItemDetails = JSON.parse(stringifyItemDetails);

        this.global.hideDialog();

        /** upload file */
        this.myForm = new FormGroup({
          currentID: new FormControl(parseItemDetails.id),
          file: new FormControl(''),
          fileSource: new FormControl('')
        });

      }
    });
  }

  onSaveChanges(update: NgForm): void {
    const category: ICategory = {
      id: update.form.value.id,
      nameCategory: update.form.value.nameCategory,
      iconCategory: update.form.value.iconCategory,
    };

    this.api.updateCategory(category).subscribe(props => {
      this.global.dialogSuccessSaveEdit();
    });

  }

  /** upload file start */
  get f(): void {
    return this.myForm.controls;
  }
  onFileChange(event: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.myForm.patchValue({
          fileSource: reader.result
        });
      };
    }
  }
  onSendFile(): void {
    /** upload file start */
    // tslint:disable-next-line:max-line-length
    this.httpUploadFile.post(this.url.Upload + 'category', this.myForm.value).subscribe(props => {
      this.global.dialogSuccessUploadFile();
    });
  }

}
