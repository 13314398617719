<div class="d-block d-lg-none basic-menu-footer-mobile">

  <div class="container-xxl">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="space"></div>
      </div>
    </div>
  </div>

  <div class="container-xxl position-fixed w-100 mt-3">
    <div class="row justify-content-center">
      <div class="col-11 bg-menu align-self-center">

        <div class="row justify-content-center">

          <div class="col-3 p-0">
            <div class="card text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/home">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Strona<br/>Główna</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">home</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/instrukcja-korzystania-z-aplikacji">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Instrukcja<br/>korzystania<br/>z aplikacji</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">view_headline</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/jak-wybrac-fajerwerki">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Jak wybierać <br/>fajerwerki</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">help</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/propozycje-pokazow-piromax">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Propozycje<br/>piromax</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">apps</span>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center copyright-mobile">
          <div class="col-12 fw-normal text-center text-white-50 text-uppercase">
            <p class="mb-0 small"><span class="material-icons copyright-icons mr-2">copyright</span>2020 Piromax</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
