import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';
import { HomeComponent } from './front/pages/home/home.component';
import { LoginComponent } from './dashboard/pages/login/login.component';
import { DashboardComponent } from './dashboard/pages/dashboard/dashboard.component';
import {AddProductsComponent} from './dashboard/pages/product/add-products/add-products.component';
import {EditProductsComponent} from './dashboard/pages/product/edit-products/edit-products.component';
import {ListProductsComponent} from './dashboard/pages/product/list-products/list-products.component';
import {ListCategoriesComponent} from './dashboard/pages/categories/list-categories/list-categories.component';
import {EditCategoriesComponent} from './dashboard/pages/categories/edit-categories/edit-categories.component';
import {AddCategoriesComponent} from './dashboard/pages/categories/add-categories/add-categories.component';
import {ListShowsComponent} from './dashboard/pages/create-shows/list-shows/list-shows.component';
import {AddShowsComponent} from './dashboard/pages/create-shows/add-shows/add-shows.component';
import {EditShowsComponent} from './dashboard/pages/create-shows/edit-shows/edit-shows.component';
import {InstructionForUsingTheApplicationComponent} from './front/pages/instruction-for-using-the-application/instruction-for-using-the-application.component';
import {HowChooseFireworksComponent} from './front/pages/how-choose-fireworks/how-choose-fireworks.component';
import {SuggestionShowsComponent} from './front/pages/suggestion-shows/suggestion-shows.component';
import {SubpageSuggestionShowsComponent} from './front/pages/subpage-suggestion-shows/subpage-suggestion-shows.component';
import {IntroCreateClientShowsComponent} from './front/pages/intro-create-client-shows/intro-create-client-shows.component';
import {CreateYourVisualizationClientComponent} from './front/pages/create-your-visualization-client/create-your-visualization-client.component';
import {SearchSimpleComponent} from './front/pages/search/search-simple/search-simple.component';
import {SearchSimpleProductsComponent} from './front/pages/search/search-simple-products/search-simple-products.component';
import {SearchAdvancedComponent} from './front/pages/search/search-advanced/search-advanced.component';
import {RefreshPageComponent} from './front/pages/refresh-page/refresh-page.component';
import {ClientComponent} from './front/pages/client/client.component';
import {EditClientComponent} from './dashboard/pages/clients/edit-client/edit-client.component';
import {ListClientsComponent} from './dashboard/pages/clients/list-clients/list-clients.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'dashboard',
    component: LoginComponent
  },
  {
    path: 'dashboard/panel',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'list-products', component: ListProductsComponent, canActivate: [AuthGuardService] },
      { path: 'add-products', component: AddProductsComponent, canActivate: [AuthGuardService] },
      { path: 'edit-products/:id', component: EditProductsComponent, canActivate: [AuthGuardService] },
      { path: 'list-categories', component: ListCategoriesComponent, canActivate: [AuthGuardService] },
      { path: 'add-categories', component: AddCategoriesComponent, canActivate: [AuthGuardService] },
      { path: 'edit-categories/:id', component: EditCategoriesComponent, canActivate: [AuthGuardService] },
      { path: 'list-shows', component: ListShowsComponent, canActivate: [AuthGuardService] },
      { path: 'add-shows', component: AddShowsComponent, canActivate: [AuthGuardService] },
      { path: 'edit-shows/:id', component: EditShowsComponent, canActivate: [AuthGuardService] },
      { path: 'list-client', component: ListClientsComponent, canActivate: [AuthGuardService] },
      { path: 'show-client/:id', component: EditClientComponent, canActivate: [AuthGuardService] },
    ]
  },
  {
    path: 'instrukcja-korzystania-z-aplikacji',
    component: InstructionForUsingTheApplicationComponent
  },
  {
    path: 'jak-wybrac-fajerwerki',
    component: HowChooseFireworksComponent
  },
  {
    path: 'propozycje-pokazow-piromax',
    component: SuggestionShowsComponent
  },
  {
    path: 'propozycje-pokazow-piromax/:id',
    component: SubpageSuggestionShowsComponent
  },
  {
    path: 'tworzenie-pokazu',
    component: IntroCreateClientShowsComponent
  },
  {
    path: 'wizualizacja-twojego-pokazu',
    component: CreateYourVisualizationClientComponent
  },
  {
    path: 'wyszukiwanie-proste',
    component: SearchSimpleComponent
  },
  {
    path: 'wyszukiwanie-proste/:id',
    component: SearchSimpleProductsComponent
  },
  {
    path: 'wyszukiwanie-zaawansowane',
    component: SearchAdvancedComponent
  },
  {
    path: 'zapisywanie-zmian',
    component: RefreshPageComponent
  },
  {
    path: 'pokaz/:client',
    component: ClientComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
