<div class="container-fluid bg-dark mt-3">
  <div class="row justify-content-center">
    <div class="col-12">
      <mat-form-field class="w-100" appearance="outline" color="accent">
        <mat-label>Wyszukaj interesujący cię element</mat-label>
        <input matInput type="text" (keyup)="doFilter($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
        <mat-hint>Wyszukiwanie odbywa się po elementach typu: index, model, cena, YouTube, liczba strzałów, kaliber oraz wymiary </mat-hint>
      </mat-form-field>
    </div>
  </div>
</div>

<table class="table table-dark table-striped mt-3" mat-table [dataSource]="products">

  <ng-container matColumnDef="icon">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Icon</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.iconProduct">
        <div class="backgroundImage mt-2" [ngStyle]="{'background-image': 'url(' + media + product.iconProduct + ')' }"></div>
      </ng-container>
      <ng-container *ngIf="!product.iconProduct">
        <span class="material-icons display-2 mb-0 text-white-50">image_not_supported</span>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="index">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Index</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.indexProduct">
        {{product.indexProduct}}
      </ng-container>
      <ng-container *ngIf="!product.indexProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="model">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Model</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.modelProduct">
        {{product.modelProduct}}
      </ng-container>
      <ng-container *ngIf="!product.modelProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Kategoria</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.categoryProduct.length > 0">
        <ng-container *ngFor="let chosen of chosenCategory">
          <ng-container *ngFor="let currentCategory of product.categoryProduct">
            <ng-container *ngIf="chosen.id == currentCategory">
              {{chosen.nameCategory.toLowerCase()}}<br/>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="product.categoryProduct.length == 0">
        -
      </ng-container>

    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Cena</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.priceProduct">
        {{product.priceProduct}}
      </ng-container>
      <ng-container *ngIf="!product.priceProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="youtube">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>YouTube</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.videoYoutube">
        {{product.videoYoutube}}
      </ng-container>
      <ng-container *ngIf="!product.videoYoutube">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="timeAll">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Czas</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.timeVideoAll">
        {{product.timeVideoAll}}
      </ng-container>
      <ng-container *ngIf="!product.timeVideoAll">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="shoots">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Liczba strzałów</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.shootsProduct">
        {{product.shootsProduct}}
      </ng-container>
      <ng-container *ngIf="!product.shootsProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="caliber">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Kaliber</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.caliberProduct">
        {{product.caliberProduct}}
      </ng-container>
      <ng-container *ngIf="!product.caliberProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="dimensions">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Wymiary</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let product">
      <ng-container *ngIf="product.dimensionsProduct">
        {{product.dimensionsProduct}}
      </ng-container>
      <ng-container *ngIf="!product.dimensionsProduct">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th class="border-0 text-right align-middle" mat-header-cell *matHeaderCellDef>Akcje</th>
    <td class="border-0 text-right align-middle" mat-cell *matCellDef="let product">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <button
            routerLink="/dashboard/panel/edit-products/{{product.id}}"
            mat-icon-button class="text-success">
            <span class="material-icons">edit</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button
            (click)="delete(product.id)"
            mat-icon-button class="text-danger">
            <span class="material-icons">delete</span>
          </button>
        </li>
      </ul>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>

<mat-paginator class="bg-dark" [length]="5" [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 50, 75, 100, 9999]"></mat-paginator>
