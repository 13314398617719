import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorLoginComponent } from '../dialog/dialog-error-login/dialog-error-login.component';
import { DialogSuccessSaveEditComponent } from '../dialog/dialog-success-save-edit/dialog-success-save-edit.component';
import { DialogDeleteItemComponent } from '../dialog/dialog-delete-item/dialog-delete-item.component';
import { DialogCreateSuccessComponent } from '../dialog/dialog-create-success/dialog-create-success.component';
import { DialogPreloaderComponent } from '../dialog/dialog-preloader/dialog-preloader.component';
import { DialogSuccessUploadFileComponent } from '../dialog/dialog-success-upload-file/dialog-success-upload-file.component';
import { DialogInfoIsEmptyListComponent } from '../dialog/dialog-info-is-empty-list/dialog-info-is-empty-list.component';
import { DialogDownloadVideoComponent } from '../dialog/dialog-download-video/dialog-download-video.component';
import { DialogProcessSendEmailComponent } from '../dialog/dialog-process-send-email/dialog-process-send-email.component';
import { DialogSuccessSendEmailComponent } from '../dialog/dialog-success-send-email/dialog-success-send-email.component';
import { DialogErrorComponent } from '../dialog/dialog-error/dialog-error.component';
import { DialogProcessDownloadFileComponent } from '../dialog/dialog-process-download-file/dialog-process-download-file.component';
import { DialogThereAreNoProductsToDisplayComponent } from '../dialog/dialog-there-are-no-products-to-display/dialog-there-are-no-products-to-display.component';
import { DialogSuccessAddToCartComponent } from '../dialog/dialog-success-add-to-cart/dialog-success-add-to-cart.component';
import { DialogEmptyCacheComponent } from '../dialog/dialog-empty-cache/dialog-empty-cache.component';
import { DialogRefreshCacheComponent } from '../dialog/dialog-refresh-cache/dialog-refresh-cache.component';
import { DialogRefreshPlayerWithParametrUrlComponent } from '../dialog/dialog-refresh-player-with-parametr-url/dialog-refresh-player-with-parametr-url.component';
import lozad from 'lozad';
declare var tns: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  constructor(
    public dialog: MatDialog
  ) { }

  dialogErrorLogin(): void {
    this.dialog.open(DialogErrorLoginComponent);
  }
  dialogSuccessSaveEdit(): void {
    this.dialog.open(DialogSuccessSaveEditComponent);
  }
  dialogDeleteItem(): void {
    this.dialog.open(DialogDeleteItemComponent);
  }
  dialogCreateSuccess(): void {
    this.dialog.open(DialogCreateSuccessComponent);
  }
  dialogPreloader(): void {
    this.dialog.open(DialogPreloaderComponent);
  }
  dialogSuccessUploadFile(): void {
    this.dialog.open(DialogSuccessUploadFileComponent);
  }
  dialogInfoEmptyList(): void {
    this.dialog.open(DialogInfoIsEmptyListComponent);
  }
  dialogDownloadVideo(): void {
    this.dialog.open(DialogDownloadVideoComponent);
  }
  dialogProcessSend(): void {
    this.dialog.open(DialogProcessSendEmailComponent);
  }
  dialogSuccessSendEmail(): void {
    this.dialog.open(DialogSuccessSendEmailComponent);
  }
  dialogError(): void {
    this.dialog.open(DialogErrorComponent);
  }
  dialogProcessDownloadFilePdf(): void {
    this.dialog.open(DialogProcessDownloadFileComponent);
  }
  dialogThereAreNoProductsToDisplay(): void {
    this.dialog.open(DialogThereAreNoProductsToDisplayComponent);
  }
  dialogSuccessAddToCart(): void {
    this.dialog.open(DialogSuccessAddToCartComponent);
  }
  dialogEmptyCache(): void {
    this.dialog.open(DialogEmptyCacheComponent);
  }
  dialogReloadPage(): void {
    this.dialog.open(DialogRefreshCacheComponent);
  }
  dialogRefreshPageWithLastUrl(): void {
    this.dialog.open(DialogRefreshPlayerWithParametrUrlComponent);
  }
  hideDialog(): void {
    this.dialog.closeAll();
  }
  sharedFacebook(): void {
    const centerPopUp = (url, title, width, height) => {
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 4;
      const popUp = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
    };
    centerPopUp('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, 'Udostępnij na facebooku', 765, 465);
  }
  secondsToTime(e: any): any {
    // const h = Math.floor(e / 3600).toString().padStart( 2, '0');
    const  m = Math.floor(e % 3600 / 60).toString().padStart( 2, '0');
    const  s = Math.floor(e % 60).toString().padStart( 2, '0' );
    return m + ' min. : ' + s + ' sek.';
  }
  loaderImage(): void {
    // help: <div class="backgroundImage mt-2 loader-image-background" [attr.data-background-image]="media + product.iconProduct"></div>
    const backgroundImage = lozad('.loader-image-background', {
      load: (el) => {
        el.src = el.getAttribute('data-background-image');
        el.src = el.setAttribute('style', 'background-image: url(' + el.src + ')');
        el.onload = () => {};
      }
    });
    const srcImage = lozad('.loader-image img', {
      load: (el) => {
        el.src = el.getAttribute('data-src');
        el.onload = () => {
          el.classList.remove('opacity-0');
        };
      }
    });
    setTimeout(() => {
      backgroundImage.observe();
      srcImage.observe();
    }, 300);
  }
  carouselProduct(): void {
    const slider = tns({
      container: '.carousel-products',
      items: 4,
      loop: false,
      nav: false,
      navPosition: 'bottom',
      controlsContainer: '#carousel-products-controls',
    });
  }
}
