import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {BackEndApiService} from '../../../service/back-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.css']
})
export class ListClientsComponent implements OnInit {

  clients;
  columns: string[] = [ 'lp', 'data', 'email', 'price', 'time',  'action' ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private api: BackEndApiService,
    private global: GlobalService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.api.getClients().subscribe(props => {
      this.clients = new MatTableDataSource(props);
      this.clients.data = props;
      this.clients.paginator = this.paginator;

      if (props.length > 0) {
        this.global.hideDialog();
      }

      if (props.length === 0) {
        this.global.dialogInfoEmptyList();
      }

    });
  }

  delete(id: number | string): void {
    this.api.deleteClient(id).subscribe(props => {
      console.log(props);
      this.clients.data = this.clients.data.filter(item => item.id !== id);
      this.global.dialogDeleteItem();

      if (this.clients.length === 0) {
        this.global.dialogInfoEmptyList();
      }

    });
  }

}
