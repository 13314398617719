<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-3">
  <div class="row justify-content-center mb-3">
    <div class="col-9 col-sm-8 col-md-7 col-lg-6">
      <div class="card" mat-ripple routerLink="/wizualizacja-twojego-pokazu">
        <div class="card-body text-uppercase text-center">
          <div class="d-none d-lg-block">
            <div class="display-6 mb-0 fw-normal">
              Zaprojektuj własny <br/> pokaz pirotechniczny
            </div>
          </div>
          <!-- mobile -->
          <div class="d-block d-lg-none">
            <div class="h4 mb-0 fw-normal">
              Zaprojektuj <br/> własny pokaz <br/> pirotechniczny
            </div>
          </div>
          <!-- mobile end -->
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-7">
      <div class="d-none d-lg-block">
        <div class="h3 fw-normal text-center text-uppercase">
          Lub <br/> zobacz propozycje pokazów piromax:
        </div>
      </div>
      <!-- mobile -->
      <div class="d-block d-lg-none">
        <div class="h4 fw-normal text-center text-uppercase">
          Lub zobacz propozycje <br/> pokazów piromax:
        </div>
      </div>
      <!-- mobile end -->
    </div>
  </div>
</div>
<app-shared-suggestion-shows></app-shared-suggestion-shows>
<app-basic-menu></app-basic-menu>
