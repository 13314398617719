<div class="text-center mb-3">
  <span class="material-icons display-2">mark_email_read</span>
</div>
<div class="text-center">
  <h1 mat-dialog-title>Wiadomość została wysłana</h1>
  <div mat-dialog-content>Na podany adres E-mail, został wysłany link do bierzącego pokazu</div>
  <div mat-dialog-actions>
    <button class="mx-auto" mat-button mat-dialog-close mat-raised-button color="primary" (click)="close()">Zamknij</button>
  </div>
</div>
