<form #update="ngForm">
  <div class="container-fluid mt-3">
    <div class="row justify-content-center">
      <div class="col-3 align-self-center">
        <h4 class="mb-0">Edycja</h4>
      </div>
      <div class="col-9 align-self-center text-right">
        <button mat-raised-button class="btn-success rounded-0" (click)="onSaveChanges(update)">Zapisz zmiany</button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-12">
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <ng-container *ngIf="itemDetails | async as product">

    <!-- hide input id start -->
    <mat-form-field appearance="fill" color="accent" class="w-100 d-none">
      <mat-label>Model produktu ( np. PXC201 )</mat-label>
      <input name="id" matInput [(ngModel)]="product.id">
    </mat-form-field>
    <!-- hide input id end -->

    <div class="container-fluid">

      <div class="row justify-content-center mb-3">
        <div class="col-12">
          <mat-slide-toggle color="primary" name="statusPublicationProduct" [(ngModel)]="product.statusPublicationProduct">
            Publiczny ?
          </mat-slide-toggle>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Index produktu ( np. Alternative )</mat-label>
            <input name="indexProduct" matInput [(ngModel)]="product.indexProduct">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Model produktu ( np. PXC201 )</mat-label>
            <input name="modelProduct" matInput [(ngModel)]="product.modelProduct">
            <mat-icon matSuffix>priority_high</mat-icon>
            <mat-hint>Info: Pole wymagane</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>YouTube ID</mat-label>
            <input name="videoYoutube" matInput [(ngModel)]="product.videoYoutube">
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Cena</mat-label>
            <input name="priceProduct" matInput [(ngModel)]="product.priceProduct">
          </mat-form-field>
        </div>
      </div>

      <div class="row justify-content-center mt-3 mb-3">
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Start video</mat-label>
            <input name="timeVideoStart" matInput [(ngModel)]="product.timeVideoStart">
            <mat-hint>Info: np. 30, wartości wyrażane w sekundach</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Koniec video</mat-label>
            <input name="timeVideoEnd" matInput [(ngModel)]="product.timeVideoEnd">
            <mat-hint>Info: np. 30, wartości wyrażane w sekundach</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Liczba strzałów</mat-label>
            <input name="shootsProduct" matInput [(ngModel)]="product.shootsProduct">
            <mat-hint>Info: np. 120</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Kaliber</mat-label>
            <input name="caliberProduct" matInput [(ngModel)]="product.caliberProduct">
            <mat-hint>Info: np. 50</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row justify-content-center mb-3">
        <div class="col-4">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Czas całkowity</mat-label>
            <input name="timeVideoAll" matInput [(ngModel)]="product.timeVideoAll">
            <mat-hint>Info: np. 21</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Wymiary opakowania</mat-label>
            <input name="dimensionsProduct" matInput [(ngModel)]="product.dimensionsProduct">
            <mat-hint>Info: np. 120 x 300 x 500</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Kategoria produktu</mat-label>
            <mat-select name="categoryProduct" [(ngModel)]="product.categoryProduct" multiple>
              <ng-container *ngFor="let category of optionValueCategories">
                <mat-option [value]="category.id">{{category.nameCategory}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-3 align-self-center text-center">

          <ng-container *ngIf="imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block mb-3" [ngStyle]="{'background-image': 'url('+ imageSrc + ')' }"></div>
          </ng-container>

          <ng-container *ngIf="!imageSrc">
            <div class="backgroundImageEditPage mx-auto d-block mb-3" *ngIf="product.iconProduct" [ngStyle]="{'background-image': 'url('+ media + product.iconProduct + ')' }"></div>
            <div class="text-center mt-3 mb-3" *ngIf="!product.iconProduct">
              <span class="material-icons display-1 mb-0 text-white-50">image_not_supported</span>
            </div>
          </ng-container>

          <div [formGroup]="myForm">
            <button mat-raised-button>
              <input
                formControlName="file"
                id="file"
                type="file"
                class="file-icon"
                (change)="onFileChange($event)">
            </button>
            <button class="mt-3" mat-raised-button color="primary" type="submit" (click)="onSendFile()">Wyślij na serwer</button>
          </div>

        </div>
        <div class="col-9">
          <mat-form-field appearance="fill" color="accent" class="w-100">
            <mat-label>Opis produktu</mat-label>
            <textarea name="descriptionProduct" matInput rows="25" [(ngModel)]="product.descriptionProduct"></textarea>
          </mat-form-field>
        </div>
      </div>

    </div>
  </ng-container>
</form>
