import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instruction-for-using-the-application',
  templateUrl: './instruction-for-using-the-application.component.html',
  styleUrls: ['./instruction-for-using-the-application.component.css']
})
export class InstructionForUsingTheApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
