import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BackEndApiService } from '../../../service/back-end-api.service';
import { GlobalService } from '../../../../global/global.service';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent implements OnInit {

  products;
  columns: string[] = [ 'icon', 'index', 'model', 'category', 'price', 'youtube', 'timeAll', 'shoots', 'caliber', 'dimensions', 'action' ];
  media = this.url.Media;
  chosenCategory;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private api: BackEndApiService,
    private global: GlobalService,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.api.getProducts().subscribe(props => {
      this.products = new MatTableDataSource(props);
      this.products.data = props;
      this.products.paginator = this.paginator;

      if (props.length > 0) {
        this.global.hideDialog();
      }

      if (props.length === 0) {
        this.global.dialogInfoEmptyList();
      }

      this.api.getCategories().subscribe(categoryProps => {
        this.chosenCategory = categoryProps;
      });

    });
  }

  delete(id: number | string): void {
    this.api.deleteProduct(id).subscribe(props => {
      this.products.data = this.products.data.filter(item => item.id !== id);
      this.global.dialogDeleteItem();
    });
  }

  public doFilter = (value: string) => {
    this.products.filter = value.trim().toLocaleLowerCase();
  }

}
