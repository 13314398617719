import {Component, ElementRef, OnInit} from '@angular/core';
import {GlobalService} from '../../../global/global.service';
import {MatDialog} from '@angular/material/dialog';
import {FrontEndApiService} from '../../service/front-end-api.service';
import {DialogPopUpProductComponent} from '../../../dialog/dialog-pop-up-product/dialog-pop-up-product.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DialogDownloadVideoClientComponent} from '../../../dialog/dialog-download-video-client/dialog-download-video-client.component';
import {UrlService} from '../../../global/url.service';
declare var html2pdf: any;

let apiLoaded = false;

export interface DialogClientShows {
  cacheDialog: [];
  cacheTime?: string;
  cachePrice?: string;
}

@Component({
  selector: 'app-create-your-visualization-client',
  templateUrl: './create-your-visualization-client.component.html',
  styleUrls: ['./create-your-visualization-client.component.css']
})
export class CreateYourVisualizationClientComponent implements OnInit {
  carousel = [];
  media = this.url.Media;
  tablePDF = [];
  products = [];

  player;
  i = 0;
  playerList;

  idProduct: string;
  showButtonAddToCart: boolean;

  cacheTime: any[] = [];
  cacheDisplayTime = 0;
  cacheDisplayPrice = 0;
  sumTime: number = null;
  sumPrice: number = null;
  cacheProducts: any[] = [];

  createProductsForDeleteItem: any[] = [];
  refreshCache = false;

  cacheDialog: [];

  hideIntroYouTube = false;
  showPlayAgain = true;

  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    public dialog: MatDialog,
    private elementRef: ElementRef,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    if (!apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }

    this.global.dialogPreloader();

    if (JSON.parse(window.localStorage.getItem('product'))) {
      this.cacheProducts = JSON.parse(window.localStorage.getItem('product'));

      this.api.getProducts().subscribe(props => {
        props.forEach((index) => {
          this.cacheProducts.forEach((productID) => {
            if (productID === index.id) {
              this.createProductsForDeleteItem.push(index);
            }
          });
        });
      });
    } else {
      // this.global.dialogEmptyCache();
      this.global.hideDialog();
    }
    if (window.localStorage.getItem('time')) {
      this.sumTime = parseInt(window.localStorage.getItem('time'), 10);
      this.cacheDisplayTime = this.global.secondsToTime(window.localStorage.getItem('time'));
    }
    if (window.localStorage.getItem('price')) {
      this.sumPrice = parseInt(window.localStorage.getItem('price'), 10);
      this.cacheDisplayPrice = parseInt(window.localStorage.getItem('price'), 10);
    }

    this.api.getProducts().subscribe(props => {
      const stringifyProducts = JSON.stringify(props);
      const parseProducts = JSON.parse(stringifyProducts);
      this.products = props;
      if (this.cacheProducts.length > 0) {
        this.cacheProducts.forEach((index) => {
          parseProducts.forEach((indexProduct) => {
            if (index === indexProduct.id) {
              this.carousel.push(indexProduct);
              this.tablePDF.push(indexProduct);
            }
          });
        });
        if (this.carousel.length > 0) {
          this.global.hideDialog();
          setTimeout(() => {
            this.global.carouselProduct();
            this.global.loaderImage();
          }, 10);
        }
        if (props) {
          if (this.carousel.length > 0) {
            this.global.hideDialog();
            setTimeout(() => {
              this.global.carouselProduct();
              this.global.loaderImage();
            }, 10);

            this.playerList = this.carousel.map((ele) => ({
              lp: this.i,
              videoId: ele.videoYoutube,
              // tslint:disable-next-line:radix
              startSeconds: parseInt(ele.timeVideoStart),
              // tslint:disable-next-line:radix
              endSeconds: parseInt(ele.timeVideoEnd)
            }));

          }
        }
      }
    });
  }

  configureYouTube(): void {
    this.player = new YT.Player('ytplayer', {
      width: '100%',
      height: 450,
      videoId: this.playerList[0].videoId,
      events: {
        onReady: (event: YT.PlayerEvent) => {
          console.log(event);

          // event.target.playVideo();
          /*console.log('first video');
          this.player.loadVideoById({
            videoId: this.playerList[0].videoId,
            startSeconds: this.playerList[0].startSeconds,
            endSeconds: this.playerList[0].endSeconds
          });*/

        },
        onStateChange: (event: YT.OnStateChangeEvent) => {
          let state = 'undefined';
          switch (event.data) {
            case YT.PlayerState.UNSTARTED:
              state = 'YT.PlayerState.UNSTARTED';
              break;
            case YT.PlayerState.ENDED:
              state = 'YT.PlayerState.ENDED';

              if (this.player.getVideoLoadedFraction() > 0) {
                if (this.i < this.playerList.length) {

                  this.i++;

                  if (this.i + 1 > this.playerList.length) {
                    this.showPlayAgain = false;
                  }

                  this.player.loadVideoById({
                    videoId: this.playerList[this.i].videoId,
                    startSeconds: this.playerList[this.i].startSeconds,
                    endSeconds: this.playerList[this.i].endSeconds
                  });

                }
              }

              break;
            case YT.PlayerState.PLAYING:
              state = 'YT.PlayerState.PLAYING';
              break;
            case YT.PlayerState.PAUSED:
              state = 'YT.PlayerState.PAUSED';
              break;
            case YT.PlayerState.BUFFERING:
              state = 'YT.PlayerState.BUFFERING';
              break;
            case YT.PlayerState.CUED:
              state = 'YT.PlayerState.CUED';
              break;
            default:
              state = 'Nieznany błąd' + event.data;
          }
        }
      },
      playerVars: {
        autoplay: 1,
        loop: 1,
        controls: 0,
        rel: 0,
        start: this.playerList[0].startSeconds,
        end: this.playerList[0].endSeconds
      }
    });
  }

  onPopUpProduct(id: any): void {
    const dialogRef = this.dialog.open(DialogPopUpProductComponent, {
      data: {idProduct: id, showButtonAddToCart: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.idProduct = result;
    });
  }

  onRefreshCache(): void {
    this.global.dialogReloadPage();
  }

  drop(event: CdkDragDrop<string[]>, mobile?: boolean): void {
    this.refreshCache = true;
    const createChangeCache = [];
    moveItemInArray(this.carousel, event.previousIndex, event.currentIndex);
    this.carousel.forEach((value) => {
      createChangeCache.push(value.id);
    });
    localStorage.setItem('product', JSON.stringify(createChangeCache));
    if (mobile) {
      this.onRefreshCache();
    }
  }

  onDelete(index, id: any, mobile?: boolean): void {
    this.refreshCache = true;
    const elem = this.elementRef.nativeElement.querySelector('#client-carousel-item' + index);
    const getIndex = this.cacheProducts.findIndex(i => i === id);
    const getIndexBinding = this.createProductsForDeleteItem.findIndex(i => i.id === id);
    this.cacheProducts.splice(getIndex, 1);
    this.createProductsForDeleteItem.splice(getIndexBinding, 1);
    // elem.style.display = 'none';
    elem.parentNode.removeChild(elem);

    let countProducts = [];
    countProducts = this.createProductsForDeleteItem.map((ele) => ({
      timeAll: parseInt(ele.timeVideoAll, 10),
      price: parseInt(ele.priceProduct, 10)
    }));
    const time = [];
    const price = [];
    countProducts.forEach(t => {
      time.push(t.timeAll);
      price.push(t.price);
    });
    let sumTime: number = null;
    time.forEach((value) => {
      sumTime += value;
    });
    let sumPrice: null = null;
    price.forEach((value) => {
      sumPrice += value;
    });
    this.cacheDisplayTime = this.global.secondsToTime(sumTime);
    this.cacheDisplayPrice = parseInt(sumPrice, 10);
    localStorage.setItem('product', JSON.stringify(this.cacheProducts));
    localStorage.setItem('time', JSON.stringify(sumTime));
    localStorage.setItem('price', JSON.stringify(sumPrice));

    if (this.createProductsForDeleteItem.length === 0) {
      this.global.dialogEmptyCache();
      localStorage.clear();
      this.onRefreshCache();
    }

    this.onRefreshCache();

  }

  onStartYouTube(): void {
    this.hideIntroYouTube = true;
    if (window.YT) {
      this.configureYouTube();
      return;
    }
    window.onYouTubeIframeAPIReady = () => this.configureYouTube();
    this.player.playVideo();
  }

  onDownloadVideo(): void {
    // cacheTime?: string; this.cacheDisplayTime
    // cachePrice?: string; this.cacheDisplayPrice
    const dialogRefClient = this.dialog.open(DialogDownloadVideoClientComponent, {
      data: {cacheDialog: this.cacheProducts, cacheTime: this.cacheDisplayTime, cachePrice: this.cacheDisplayPrice}
    });
    dialogRefClient.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.cacheProducts = result;
      this.global.dialogReloadPage();
    });
  }

  onSharedToFacebook(): void {
    this.global.sharedFacebook();
  }

  onDownloadListProducts(): void {
    this.global.dialogProcessDownloadFilePdf();
    const today = new Date();
    // tslint:disable-next-line:max-line-length
    const timeCurrent = today.getFullYear() + '-' + today.getMonth() + '-' + today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const options = {
      margin: 0.3,
      filename: 'piromax-' + timeCurrent + '.pdf',
      image: {
        type: 'jpeg',
        quality: 0.98
      },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        useCORS: true,
        dpi: 300
      },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait'
      }
    };

    const objstr = document.getElementById('block1').innerHTML;

    let strr = '<html><head><title>Piromax - Producent petard i fajerwerków</title>';
    strr += '</head><body>';
    strr += '<div style="color: #fff; background-color: #333; padding: 10px; text-align: center;">Piromax - Producent petard i fajerwerków</div>';
    strr += '<div style="color: #333;">' + objstr + ' </div>';
    strr += '</body></html>';

    const element = document.getElementById('create-pdf');
    html2pdf().from(strr).set(options).save();
  }

}
