import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-choose-fireworks',
  templateUrl: './how-choose-fireworks.component.html',
  styleUrls: ['./how-choose-fireworks.component.css']
})
export class HowChooseFireworksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

}
