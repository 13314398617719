import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  login(username: string, password: string): any {
    if (username === 'admin' && password === 'admin') {
      localStorage.setItem('currentUser', 'loggedin');
      return true;
    }
  }
  logout(): void {
    localStorage.removeItem('currentUser');
  }
  public get loggedIn(): boolean {
    return (localStorage.getItem('currentUser') !== null);
  }

}
