import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-error-login',
  templateUrl: './dialog-error-login.component.html',
  styleUrls: ['./dialog-error-login.component.css']
})
export class DialogErrorLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
