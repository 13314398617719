import {Component, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(private newVersionApp: SwUpdate) {}
  ngOnInit(): void {
    if (this.newVersionApp.isEnabled) {
      this.newVersionApp.available.subscribe(() => {
        if (confirm('Nowa wersja aplikacji jest dostępna. Czy załadować nową wersje?')) {
          window.location.reload();
        }
      });
    }
  }
}
