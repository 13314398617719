<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-0 mt-lg-3">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-3 align-self-center text-center">
      <div class="card card-cart d-none d-lg-bloc" mat-ripple>
        <div class="card-body">
          <div class="card-title text-red text-uppercase h4 mb-0">Twój pokaz:</div>
          <div class="card-title h5 text-red mb-0">
            <ng-container *ngIf="!cacheDisplayTime">
              00 min 00 sek - 0 zł
            </ng-container>
            <ng-container *ngIf="cacheDisplayTime">
              {{cacheDisplayTime}} - {{cacheDisplayPrice}} zł
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card card-return mt-1 d-none d-lg-block" mat-ripple routerLink="/wizualizacja-twojego-pokazu">
        <div class="card-body">
          <div class="card-title h5 mb-0 fw-normal text-uppercase"><span class="position-relative material-icons mr-1">west</span>Wróć do pokazu</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7 align-self-center">
      <div class="d-none d-lg-block">
        <div class="display-6 mb-0 text-uppercase text-center fw-normal">Wyszukiwanie Proste</div>
      </div>
      <!-- mobile -->
      <div class="d-block d-lg-none">
        <ul class="list-inline text-center">
          <li class="list-inline-item">
            <div class="h3 mb-0 text-uppercase text-center title-add-another-launcher fw-normal">
              <button mat-raised-button color="accent" class="pl-2 pr-2" routerLink="/wizualizacja-twojego-pokazu">
                <span class="material-icons icon-return-to-show-mobile">keyboard_backspace</span>Wróć do pokazu
              </button>
            </div>
          </li>
          <li class="list-inline-item ml-3">
            <div class="h3 mb-0 text-uppercase text-center title-add-another-launcher fw-normal">Dodaj kolejną wyrzutnię</div>
          </li>
        </ul>
      </div>
      <!-- mobile end -->
    </div>
    <div class="col-12 col-lg-2 align-self-center" *ngFor="let cat of itemCategory">
      <div class="card card-current-category text-center d-none d-lg-block" mat-ripple>
        <div class="card-body">
          <div class="card-title break mb-0 fw-normal h6">{{cat.nameCategory}}</div>
          <div class="backgroundImageCategoryCurrent" [style]="{'background-image': 'url(' + media + cat.iconCategory + ')'}"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <div class="col-4 col-lg-3 pr-1 pl-1 mb-2" *ngFor="let prod of itemProducts">
      <div class="card card-product ml-1 mr-1">
        <div class="card-body text-center position-relative">
          <div class="card-text text-red fw-bold" *ngIf="prod.indexProduct">{{prod.indexProduct}}</div>
          <div class="h4 card-title text-red" *ngIf="prod.modelProduct">{{prod.modelProduct}}</div>
          <!-- [ngStyle]="{'background-image': 'url(' + media + itemCarousel.iconProduct + ')' }" -->
          <div class="backgroundImageProductCarouselSimpleSearch pointer mt-3 loader-image-background"
               [attr.data-background-image]="media + prod.iconProduct"
               (click)="onAddToCart(prod.id)"
          >
          </div>
          <div class="more-action">
            <button class="btn-light" matTooltip="Opis produktu" matTooltipPosition="above" mat-mini-fab aria-label="Więcej informacji" (click)="onPopUpProduct(prod.id)">
              <span class="material-icons">zoom_out_map</span>
            </button>
          </div>
        </div>
        <div class="card-footer border bg-transparent text-center">
          <ul class="list-inline pt-2 mb-0">
            <li class="list-inline-item fw-normal">cena: </li>
            <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="prod.priceProduct">{{prod.priceProduct}} zł</li>
            <li class="list-inline-item h5 mb-0" *ngIf="!prod.priceProduct"> - </li>
          </ul>
          <ul class="list-inline mb-0">
            <li class="list-inline-item fw-normal">czas: </li>
            <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="prod.timeVideoAll">{{prod.timeVideoAll}}s</li>
            <li class="list-inline-item h5 mb-0" *ngIf="!prod.timeVideoAll"> - </li>
          </ul>
          <ul class="list-inline mb-0 mb-lg-2">
            <li class="list-inline-item fw-normal">strzały: </li>
            <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="prod.shootsProduct">{{prod.shootsProduct}}</li>
            <li class="list-inline-item h5 mb-0" *ngIf="!prod.shootsProduct"> - </li>
          </ul>
          <ul class="list-unstyled text-center">
            <li>
              <ng-container *ngIf="!prod.videoYoutube || !prod.priceProduct || !prod.timeVideoAll">
                <button mat-button class="text-uppercase btn-lg btn-disable-button pt-2" color="primary" [disabled]="true">
                  <span class="material-icons">not_interested</span>
                </button>
              </ng-container>
              <ng-container *ngIf="prod.videoYoutube && prod.priceProduct && prod.timeVideoAll">
                <button mat-button class="btn-lg btn-mobile-add-to-card mt-2 btn-piromax-add-to-cart rounded-pill text-uppercase" (click)="onAddToCart(prod.id)">
                  <span class="material-icons mr-2">add</span>Dodaj
                </button>
              </ng-container>

              <div class="d-none alert alert-info" *ngIf="prod.videoYoutube && prod.priceProduct && prod.timeVideoAll">Tak, wszystkie pola wypełnione</div>
              <div class="d-none alert alert-info" *ngIf="!prod.videoYoutube || !prod.priceProduct || !prod.timeVideoAll">Nie, wszystkie pola wypełnione</div>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
<app-basic-menu></app-basic-menu>
