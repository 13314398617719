<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <div class="col-12 text-center text-uppercase">
      <div class="h1 fw-normal title-visualizations-of-your-show-mobile">Wizualizacja twojego pokazu</div>
    </div>
  </div>
</div>
<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-2"></div>
    <div class="col-11 col-lg-8">
      <div class="youtube-player position-relative">
        <!-- <div class="youtube-background-dark youtube-no-products"></div> -->
        <div class="youtube-background-dark youtube-play" [hidden]="hideIntroYouTube" (click)="onStartYouTube()"></div>

        <youtube-player class="youtube-player" id="ytplayer"></youtube-player>

      </div>
    </div>
    <div class="col-12 col-lg-2 d-flex flex-column justify-content-between">

      <div class="row justify-content-center d-none d-lg-block">
        <div class="col-12">
          <button class="btn btn-sm btn-dark btn-download-video w-100 rounded-pill" mat-ripple (click)="onDownloadVideo()">
            Pobierz film
          </button>
        </div>
      </div>

      <div class="row justify-content-center d-none d-lg-block">
        <div class="col-12">
          <button class="btn btn-sm btn-dark btn-shared w-100 rounded-pill" mat-ripple (click)="onSharedToFacebook()">
            Udostępnij
          </button>
        </div>
      </div>

      <div class="row justify-content-center d-none d-lg-block">
        <div class="col-12">
          <button class="btn btn-sm btn-dark btn-download-list-products w-100 small rounded-pill" mat-ripple (click)="onDownloadListProducts()">
            Pobierz listę <br/> produktów
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <div class="col-12 text-center text-uppercase">
      <div class="h1 fw-normal title-featured-products-mobile mb-3">POLECANE PRODUKTY</div>
    </div>
  </div>
</div>
<div class="container-xxl d-none d-lg-block">
  <div class="row justify-content-center">
    <div class="col-2 align-self-center">
      <img alt="Początek pokazu" src="assets/img/front/play_arrow-black.png" class="img-fluid mx-auto d-block"/>
      <div class="text-center">
        <div class="h4 fw-normal mb-0 mt-4 text-uppercase">Początek<br/>pokazu</div>
      </div>
    </div>
    <div class="col-8 align-self-center">

      <div class="row justify-content-center">

        <div class="col-12 position-relative">
          <ul class="controls list-unstyled text-center" id="carousel-products-controls" aria-label="Carousel Navigation" tabindex="0">
            <li class="prev btn btn-dark mr-1" mat-ripple data-controls="prev" aria-controls="customize" tabindex="-1">
              <span class="material-icons pt-1">navigate_before</span>
            </li>
            <li class="next btn btn-dark ml-1" mat-ripple data-controls="next" aria-controls="customize" tabindex="-1">
              <span class="material-icons pt-1">navigate_next</span>
            </li>
          </ul>
          <div class="carousel-products">
            <div *ngFor="let itemCarousel of carousel">
              <div class="card card-product ml-1 mr-1">
                <div class="card-body text-center position-relative">
                  <div class="card-text text-red fw-bold" *ngIf="itemCarousel.indexProduct">{{itemCarousel.indexProduct}}</div>
                  <div class="h4 card-title text-red" *ngIf="itemCarousel.modelProduct">{{itemCarousel.modelProduct}}</div>
                  <!-- [ngStyle]="{'background-image': 'url(' + media + itemCarousel.iconProduct + ')' }" -->
                  <div class="backgroundImageProductCarousel mt-3 mb-4 loader-image-background"
                       [attr.data-background-image]="media + itemCarousel.iconProduct"
                  >
                  </div>
                  <div class="more-action">
                    <button class="btn-light btn-sm" matTooltip="Opis produktu" matTooltipPosition="above" mat-mini-fab aria-label="Więcej informacji" (click)="onPopUpProduct(itemCarousel.id)">
                      <span class="material-icons">zoom_out_map</span>
                    </button>
                  </div>
                </div>
                <div class="card-footer border bg-transparent text-center">
                  <ul class="list-inline pt-2 mb-0">
                    <li class="list-inline-item fw-normal">cena: </li>
                    <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.priceProduct">{{itemCarousel.priceProduct}} zł</li>
                    <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.priceProduct"> - </li>
                  </ul>
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item fw-normal">czas: </li>
                    <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.timeVideoAll">{{itemCarousel.timeVideoAll}}s</li>
                    <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.timeVideoAll"> - </li>
                  </ul>
                  <ul class="list-inline">
                    <li class="list-inline-item fw-normal">strzały: </li>
                    <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.shootsProduct">{{itemCarousel.shootsProduct}}</li>
                    <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.shootsProduct"> - </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="col-2 align-self-center">
      <img alt="Finał pokazu" src="assets/img/front/final.png" class="img-fluid mx-auto d-block"/>
      <div class="text-center">
        <div class="h4 fw-normal mb-0 mt-4 text-uppercase">Finał<br/>pokazu</div>
      </div>
    </div>
  </div>
</div>

<!-- mobile -->
<div class="container-xxl d-block d-lg-none">
  <div class="row justify-content-center pt-2">
    <div class="col-12 text-center">
      <div class="h6 text-white-50 fw-normal text-uppercase">Początek pokazu</div>
      <mat-divider></mat-divider>
    </div>
    <ng-container *ngFor="let itemCarousel of carousel">
      <div class="col-12 mt-5 mb-5">
        <div class="card pt-5 card-products-mobile position-relative">
          <div class="icon-product-mobile">
            <div class="backgroundImageProductCarousel mt-3 mb-0 loader-image-background"
                 [attr.data-background-image]="media + itemCarousel.iconProduct"
            >
            </div>
            <div class="more-action">
              <button class="btn-light btn-sm" matTooltip="Opis produktu" matTooltipPosition="above" mat-mini-fab aria-label="Więcej informacji" (click)="onPopUpProduct(itemCarousel.id)">
                <span class="material-icons">zoom_out_map</span>
              </button>
            </div>
          </div>
          <div class="card-header mt-5 text-center">
            <div class="card-product-index-mobile mt-4 text-uppercase" *ngIf="itemCarousel.indexProduct">
              <div class="card-text text-red fw-bold">{{itemCarousel.indexProduct}}</div>
            </div>
            <div class="card-products-model-mobile text-uppercase" *ngIf="itemCarousel.modelProduct">
              <div class="h3 card-title mb-0 text-red">{{itemCarousel.modelProduct}}</div>
            </div>
          </div>
          <div class="card-body text-center">
            <ul class="list-inline pt-2 mb-0">
              <li class="list-inline-item fw-normal">cena: </li>
              <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.priceProduct">{{itemCarousel.priceProduct}} zł</li>
              <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.priceProduct"> - </li>
            </ul>
            <ul class="list-inline mb-0">
              <li class="list-inline-item fw-normal">czas: </li>
              <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.timeVideoAll">{{itemCarousel.timeVideoAll}}s</li>
              <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.timeVideoAll"> - </li>
            </ul>
            <ul class="list-inline mb-0">
              <li class="list-inline-item fw-normal">strzały: </li>
              <li class="list-inline-item h5 mb-0 fw-normal" *ngIf="itemCarousel.shootsProduct">{{itemCarousel.shootsProduct}}</li>
              <li class="list-inline-item h5 mb-0" *ngIf="!itemCarousel.shootsProduct"> - </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="col-12 text-center">
      <mat-divider></mat-divider>
      <div class="h6 text-white-50 pt-2 fw-normal text-uppercase">Finał pokazu</div>
    </div>
  </div>
</div>
<!-- mobile end -->





<div id="create-pdf" class="d-none">
  <div id="block1">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col" style="width: 200px">#</th>
        <th scope="col" style="width: 300px;">Parametry</th>
        <th scope="col">Opis</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let pdf of tablePDF">
        <td>
          <img src="{{media + pdf.iconProduct}}" style="width: 150px; height: auto"/>
        </td>
        <td>
          <p>
            Model:
            <span *ngIf="pdf.modelProduct">{{pdf.modelProduct}}</span>
            <span *ngIf="!pdf.modelProduct"> - </span>
          </p>
          <p>
            Index:
            <span *ngIf="pdf.indexProduct">{{pdf.indexProduct}}</span>
            <span *ngIf="!pdf.indexProduct"> - </span>
          </p>
          <p>
            Cena:
            <span *ngIf="pdf.priceProduct">{{pdf.priceProduct}} zł</span>
            <span *ngIf="!pdf.priceProduct"> - </span>
          </p>
          <p>
            Czas:
            <span *ngIf="pdf.timeVideoAll">{{pdf.timeVideoAll}} sekund.</span>
            <span *ngIf="!pdf.timeVideoAll"> - </span>
          </p>
          <p>
            Liczba strzałów:
            <span *ngIf="pdf.shootsProduct">{{pdf.shootsProduct}}</span>
            <span *ngIf="!pdf.shootsProduct"> - </span>
          </p>
          <p>
            Kaliber:
            <span *ngIf="pdf.caliberProduct">{{pdf.caliberProduct}}</span>
            <span *ngIf="!pdf.caliberProduct"> - </span>
          </p>
          <p>
            Wymiary opakowania:
            <span *ngIf="pdf.dimensionsProduct">{{pdf.dimensionsProduct}}</span>
            <span *ngIf="!pdf.dimensionsProduct"> - </span>
          </p>
        </td>
        <td>
          <p>{{pdf.descriptionProduct}}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<app-footer></app-footer>
<!-- extended menu -->
<div class="d-block d-lg-none extended-menu-footer-mobile">

  <div class="container-xxl">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="space"></div>
      </div>
    </div>
  </div>

  <div class="container-xxl position-fixed w-100 mt-3">
    <div class="row justify-content-center">
      <div class="col-11 bg-menu align-self-center">

        <div class="row justify-content-center">

          <div class="col-3 p-0">
            <div class="card text-center rounded-0  border-left-0 bg-transparent text-uppercase h-100" mat-ripple routerLink="/wyszukiwanie-zaawansowane">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Dodaj<br/>produkt</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">add</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card text-center rounded-0  border-left-0 bg-transparent text-uppercase h-100" mat-ripple (click)="onDownloadVideo()">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Zapisz film</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">save_alt</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card rounded-0  border-left-0 text-center bg-transparent text-uppercase h-100" mat-ripple (click)="onDownloadListProducts()">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Zapisz listę<br/>produktów</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">grading</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card rounded-0  border-right-0 text-center bg-transparent text-uppercase h-100" mat-ripple (click)="onSharedToFacebook()">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Udostępnij</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">mobile_screen_share</span>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center">

          <div class="col-3 p-0">
            <div class="card rounded-0  border-left-0 text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/home">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Strona<br/>Główna</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">home</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card rounded-0  border-left-0 text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/instrukcja-korzystania-z-aplikacji">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Instrukcja<br/>korzystania<br/>z aplikacji</div>
              </div>
              <div class="card-body pt-0 pb-0 pl-1 pr-1">
                <span class="material-icons display-3">view_headline</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card rounded-0  border-left-0 text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/jak-wybrac-fajerwerki">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Jak wybierać <br/>fajerwerki</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">help</span>
              </div>
            </div>
          </div>

          <div class="col-3 p-0">
            <div class="card rounded-0  border-right-0 text-center bg-transparent text-uppercase h-100" mat-ripple routerLink="/propozycje-pokazow-piromax">
              <div class="card-header bg-transparent border-0 pb-0 pl-1 pr-1 d-flex align-items-center justify-content-center h-100">
                <div class="card-title mb-0">Propozycje<br/>piromax</div>
              </div>
              <div class="card-body pb-0 pt-0 pl-1 pr-1">
                <span class="material-icons display-3">apps</span>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center copyright-mobile">
          <div class="col-12 fw-normal text-center text-white-50 text-uppercase">
            <p class="mb-0 small"><span class="material-icons copyright-icons mr-2">copyright</span>2020 Piromax</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
