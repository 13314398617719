<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-3 mb-3">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="h3 fw-normal mb-3">Instrukcja korzystania z aplikacji</div>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div class="container-xxl">
  <div class="row justify-content-center">
    <div class="col-12">
      <p>
        - Zapoznaj się z filmem „Jak wybierać fajerwerki” – przybliży Ci on magiczny świat pirotechniki.
      </p>
      <p>
        - Określ swój budżet, ułatwi Ci on tworzenie własnego pokazu.
      </p>
      <p>
        - Określ przybliżony czas pokazu, zazwyczaj im dłuższy pokaz tym wyższa cena.
      </p>
      <p>
        - Pomyśl gdzie będziesz strzelać - czy jest miejsce na baterie strzelające kątowo (wachlarze)?
      </p>
      <p>
        - Zastanów się jakich chcesz używać produktów i z jakim kalibrem? Większy kaliber to większe efekty, ale również wyższa cena i zazwyczaj wolniejsze tempo strzelania.
      </p>
      <p>
        - Wyrzutnie o mniejszym kalibrze z kolei kosztują mniej, i często strzelają bardziej dynamicznie, natomiast wielkość efektów będzie mniejsza, a czas ich trwania krótszy.
      </p>
      <p>
        - Masz do wyboru: 1 z 9 pokazów przygotowanych przez pirotechników Piromax lub stworzenie indywidualnego pokazu.
      </p>
      <p class="h5 py-4">
        <strong>
          Jak zbudować własny pokaz - krok po kroku:
        </strong>
      </p>
      <p>
        1. Rozpocznij od przycisku „Zaprojektuj własny pokaz pirotechniczny”.
      </p>
      <p>
        2. Zacznij od przycisku „Dodaj kolejną wyrzutnię”.
      </p>
      <p>
        3. W oknie dodawania kolejnych produktów są wymienione produkty wraz z orientacyjną ceną detaliczną, czasem trwania produktu, oraz ilością strzałów.
      </p>
      <p>
        4. W oknie planowania pokazu można przestawiać kolejność wyrzutni, dodawać lub usuwać produkty.
      </p>
      <p>
        5. W oknie „Wyszukiwania zaawansowanego” możesz filtrować produkty na liście poprzez dostosowanie zakresów takich jak "Kaliber", "Czas", "Cena", lub "Liczba strzałów".
      </p>
      <p>
        6. Po kliknięciu na produkt możesz obejrzeć jego film poglądowy, dodać go do swojego pokazu, lub wrócić do listy.
      </p>

      <p class="pt-2">
        - Cały czas masz wgląd w koszty i czasu trwania całego pokazu, jak i poszczególnych produktów.
      </p>
      <p>
        - Na bieżąco, w oknie planowania pokazu generowany jest podgląd pokazu w postaci filmu.
      </p>
      <p>
        - Na koniec możesz z okna planowania pokazu pobrać wygenerowany film, udostępnić go na Facebooku, lub zapisać Twoją listę produktów w postaci wygenerowanego pliku PDF.
      </p>
    </div>
  </div>
</div>

<app-footer></app-footer>
<app-basic-menu></app-basic-menu>
