import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../../global/global.service';
import { NgForm } from '@angular/forms';
import { IProducts } from '../../../service/interface';
import { BackEndApiService } from '../../../service/back-end-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.css']
})
export class AddProductsComponent implements OnInit {

  lastProductID;
  keyIndexProduct = '';
  keyModelProduct = '';

  constructor(
    private api: BackEndApiService,
    private global: GlobalService,
    private route: Router
  ) { }

  ngOnInit(): void {}

  create(createProduct: NgForm): void {
    console.log(createProduct);
    const product: IProducts = {
      indexProduct: this.keyIndexProduct,
      modelProduct: this.keyModelProduct,
      statusPublicationProduct: true
    };
    this.api.addProduct(product).subscribe(createProductProps => {
      this.global.dialogCreateSuccess();
      if (createProductProps) {
        this.api.getProducts().subscribe(getLastProductIDProps => {
          const stringify = JSON.stringify(getLastProductIDProps.slice(-1).pop());
          const parse = JSON.parse(stringify);
          this.lastProductID = parse.id;
          this.route.navigateByUrl('/dashboard/panel/edit-products/' + this.lastProductID);
        });
      }
    });
  }

}
