import {Component, Inject, OnInit} from '@angular/core';
import {FrontEndApiService} from '../../front/service/front-end-api.service';
import {NgForm} from '@angular/forms';
import {IEmail} from '../../front/service/interfaceFront';
import {MatDialog} from '@angular/material/dialog';
import {DialogProcessSendEmailComponent} from '../dialog-process-send-email/dialog-process-send-email.component';
import {DialogSuccessSendEmailComponent} from '../dialog-success-send-email/dialog-success-send-email.component';
import {DialogErrorComponent} from '../dialog-error/dialog-error.component';


@Component({
  selector: 'app-dialog-download-video',
  templateUrl: './dialog-download-video.component.html',
  styleUrls: ['./dialog-download-video.component.css']
})
export class DialogDownloadVideoComponent implements OnInit {

  keyAddressEmail = '';
  currentSlug: string;

  constructor(
    private api: FrontEndApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  send(sendEmail: NgForm): void {
   const email: IEmail = {
     email: sendEmail.form.value.email,
     message: window.location.href
   };
   this.dialog.open(DialogProcessSendEmailComponent);
   this.api.sendDownloadVideo(email).subscribe(
     props => {
       this.dialog.open(DialogSuccessSendEmailComponent);
            }, error => {
       this.dialog.open(DialogErrorComponent);
     }
   );
  }

  close(): void {
    this.dialog.closeAll();
  }

}
