import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-refresh-cache',
  templateUrl: './dialog-refresh-cache.component.html',
  styleUrls: ['./dialog-refresh-cache.component.css']
})
export class DialogRefreshCacheComponent implements OnInit {

  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.route.navigateByUrl('zapisywanie-zmian');
    });
  }

}
