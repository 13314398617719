import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontEndApiService } from './front/service/front-end-api.service';
import { BackEndApiService } from './dashboard/service/back-end-api.service';
import { FrontPagesModule } from './front/pages/front-pages.module';
import { FrontEndSharedModule } from './front/shared/front-end-shared.module';
import { BackEndPagesModule } from './dashboard/pages/back-end-pages.module';
import { BackEndSharedModule } from './dashboard/shared/back-end-shared.module';
import { AuthenticationService } from './guards/authentication.service';
import { DialogModule } from './dialog/dialog.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FrontPagesModule,
    FrontEndSharedModule,
    BackEndPagesModule,
    BackEndSharedModule,
    DialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    AuthenticationService,
    FrontEndApiService,
    BackEndApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
