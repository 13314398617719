<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <ng-container *ngFor="let item of shows">
      <div class="col-4 mb-2 pr-1 pl-1">
        <div class="card h-100" matRipple routerLink="/propozycje-pokazow-piromax/{{item.id}}">

          <img alt="{{item.showName}}" [src]="media + item.showIcon" class="img-fluid mx-auto d-block"/>

          <div class="card-body pt-0 bg-transparent text-center">

            <ng-container *ngIf="!item.showTitle == true">
              <div class="card-title card-first-title-mobile mb-0 text-uppercase break display-5 fw-normal">
                {{item.showName}}
              </div>
            </ng-container>

            <ng-container *ngIf="item.showDescription !== ''">
              <div class="display-6 card-title card-second-title-mobile fw-normal">
                <p class="card-text break">{{item.showDescription}}</p>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-footer></app-footer>
