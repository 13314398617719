import { Component, OnInit } from '@angular/core';
import {FrontEndApiService} from '../../../service/front-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-search-simple',
  templateUrl: './search-simple.component.html',
  styleUrls: ['./search-simple.component.css']
})
export class SearchSimpleComponent implements OnInit {

  categories;
  media = this.url.Media;

  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.api.getCategories().subscribe(props => {
      this.categories = props;
      if (this.categories.length > 0) {
        this.global.hideDialog();
        this.global.loaderImage();
      }
    });
  }

}
