<div class="text-center mb-3">
  <span class="material-icons display-2">priority_high</span>
</div>
<div class="text-center">
  <h1 mat-dialog-title>Brak produktów umożliwiających utworzenie pokazu</h1>
  <div mat-dialog-content>Proszę dodać produkty</div>
  <div mat-dialog-actions>
    <button class="mx-auto" (click)="onCloseDialog()" mat-button mat-dialog-close mat-raised-button color="primary">Zamknij</button>
  </div>
</div>
