import { Component, OnInit } from '@angular/core';
import {FrontEndApiService} from '../../../service/front-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-shared-suggestion-shows',
  templateUrl: './shared-suggestion-shows.component.html',
  styleUrls: ['./shared-suggestion-shows.component.css']
})
export class SharedSuggestionShowsComponent implements OnInit {

  shows;
  media = this.url.Media;
  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.api.getShows().subscribe(props => {
      if (props.length > 0) {
        this.global.hideDialog();
        this.shows = props;
        this.global.loaderImage();
      }
    });
  }

}
