import {Component, OnInit, ViewChild} from '@angular/core';
import {FrontEndApiService} from '../../service/front-end-api.service';
import {GlobalService} from '../../../global/global.service';
import {YouTubePlayer} from '@angular/youtube-player';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DialogPopUpProductComponent} from '../../../dialog/dialog-pop-up-product/dialog-pop-up-product.component';
import {UrlService} from '../../../global/url.service';
declare var html2pdf: any;

export interface DialogDataPopUpProduct {
  idProduct: string;
  showButtonAddToCart: boolean;
}

let apiLoaded = false;

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  itemDetails;
  allProducts = [];
  carousel = [];
  media = this.url.Media;
  tablePDF = [];

  player;
  i = 0;
  playerList;

  idProduct: string;
  showButtonAddToCart: boolean;

  hideIntroYouTube = false;

  @ViewChild(YouTubePlayer) youtubePlayer!: YouTubePlayer;
  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    if (!apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }

    this.global.dialogPreloader();

    this.route.paramMap.subscribe(params => {
      this.api.getClient(params.get('client')).subscribe((propsClient) => {
        const stringifyClient = JSON.stringify(propsClient);
        const parseClient = JSON.parse(stringifyClient);
        this.api.getProducts().subscribe( propsProducts => {
          parseClient[0].playlist.forEach((indexClientID) => {
            propsProducts.forEach((indexProduct) => {
              if (indexProduct.id === indexClientID) {
                this.carousel.push(indexProduct);
                this.tablePDF.push(indexProduct);
              }
            });
          });

          if (propsClient) {
            if (this.carousel.length > 0) {
              this.global.hideDialog();
              setTimeout(() => {
                this.global.carouselProduct();
                this.global.loaderImage();
              }, 10);

              this.playerList = this.carousel.map((ele) => ({
                videoId: ele.videoYoutube,
                // tslint:disable-next-line:radix
                startSeconds: parseInt(ele.timeVideoStart),
                // tslint:disable-next-line:radix
                endSeconds: parseInt(ele.timeVideoEnd)
              }));

            }
          }

        });
      });
    });

  }

  configureYouTube(): void {
    this.player = new YT.Player('ytplayer', {
      width: '100%',
      height: 450,
      videoId: this.playerList[0].videoId,
      events: {
        onReady: (event: YT.PlayerEvent) => {
          console.log(event);

          // event.target.playVideo();
          /*console.log('first video');
          this.player.loadVideoById({
            videoId: this.playerList[0].videoId,
            startSeconds: this.playerList[0].startSeconds,
            endSeconds: this.playerList[0].endSeconds
          });*/

        },
        onStateChange: (event: YT.OnStateChangeEvent) => {
          let state = 'undefined';
          switch (event.data) {
            case YT.PlayerState.UNSTARTED:
              state = 'YT.PlayerState.UNSTARTED';
              break;
            case YT.PlayerState.ENDED:
              state = 'YT.PlayerState.ENDED';

              if (this.player.getVideoLoadedFraction() > 0) {
                if (this.i < this.playerList.length) {

                  this.i++;

                  this.player.loadVideoById({
                    videoId: this.playerList[this.i].videoId,
                    startSeconds: this.playerList[this.i].startSeconds,
                    endSeconds: this.playerList[this.i].endSeconds
                  });
                }
              }

              break;
            case YT.PlayerState.PLAYING:
              state = 'YT.PlayerState.PLAYING';
              break;
            case YT.PlayerState.PAUSED:
              state = 'YT.PlayerState.PAUSED';
              break;
            case YT.PlayerState.BUFFERING:
              state = 'YT.PlayerState.BUFFERING';
              break;
            case YT.PlayerState.CUED:
              state = 'YT.PlayerState.CUED';
              break;
            default:
              state = 'Nieznany błąd' + event.data;
          }
        }
      },
      playerVars: {
        autoplay: 1,
        loop: 1,
        controls: 0,
        rel: 0,
        start: this.playerList[0].startSeconds,
        end: this.playerList[0].endSeconds
      }
    });
  }

  onPopUpProduct(id: any): void {
    const dialogRef = this.dialog.open(DialogPopUpProductComponent, {
      data: {idProduct: id, showButtonAddToCart: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.idProduct = result;
    });
  }

  onStartYouTube(): void {
    this.hideIntroYouTube = true;
    if (window.YT) {
      this.configureYouTube();
      return;
    }
    window.onYouTubeIframeAPIReady = () => this.configureYouTube();
    this.player.playVideo();
  }

  onDownloadVideo(): void {
    this.global.dialogDownloadVideo();
  }

  onSharedToFacebook(): void {
    this.global.sharedFacebook();
  }

  onDownloadListProducts(): void {
    this.global.dialogProcessDownloadFilePdf();
    const today = new Date();
    // tslint:disable-next-line:max-line-length
    const timeCurrent = today.getFullYear() + '-' + today.getMonth() + '-' + today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const options = {
      margin: 0.3,
      filename: 'piromax-' + timeCurrent + '.pdf',
      image: {
        type: 'jpeg',
        quality: 0.98
      },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        useCORS: true,
        dpi: 300
      },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait'
      }
    };

    const objstr = document.getElementById('block1').innerHTML;

    let strr = '<html><head><title>Piromax - Producent petard i fajerwerków</title>';
    strr += '</head><body>';
    strr += '<div style="color: #fff; background-color: #333; padding: 10px; text-align: center;">Piromax - Producent petard i fajerwerków</div>';
    strr += '<div style="color: #333;">' + objstr + ' </div>';
    strr += '</body></html>';

    const element = document.getElementById('create-pdf');
    html2pdf().from(strr).set(options).save();
  }

}
