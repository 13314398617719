<table class="table table-dark table-striped bg-transparent" mat-table [dataSource]="clients">

  <ng-container matColumnDef="lp">
    <th class="border-0 align-middle col-1" mat-header-cell *matHeaderCellDef>#</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let item = index">
      {{item + 1}}
    </td>
  </ng-container>

  <ng-container matColumnDef="data">
    <th class="border-0 align-middle col-3" mat-header-cell *matHeaderCellDef>Data</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let item">
      <ng-container *ngIf="item.currentData">
        {{item.currentData}}
      </ng-container>
      <ng-container *ngIf="!item.currentData">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Adres E-mail</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let item">
      <ng-container *ngIf="item.email">
        {{item.email}}
      </ng-container>
      <ng-container *ngIf="!item.email">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Cena</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let item">
      <ng-container *ngIf="item.Price">
        {{item.Price}} zł
      </ng-container>
      <ng-container *ngIf="!item.Price">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th class="border-0 align-middle" mat-header-cell *matHeaderCellDef>Czas trwania pokazu</th>
    <td class="border-0 align-middle" mat-cell *matCellDef="let item">
      <ng-container *ngIf="item.Time">
        {{item.Time}}
      </ng-container>
      <ng-container *ngIf="!item.Time">
        -
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th class="border-0 text-right align-middle" mat-header-cell *matHeaderCellDef>Akcje</th>
    <td class="border-0 text-right align-middle" mat-cell *matCellDef="let item">
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <button
            routerLink="/dashboard/panel/show-client/{{item.id}}"
            mat-icon-button class="text-success">
            <span class="material-icons">edit</span>
          </button>
        </li>
        <li class="list-inline-item">
          <button
            (click)="delete(item.id)"
            mat-icon-button class="text-danger">
            <span class="material-icons">delete</span>
          </button>
        </li>
      </ul>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
