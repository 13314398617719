<mat-toolbar>
  <mat-list class="inline-list">
    <mat-list-item> Piromax Application </mat-list-item>
  </mat-list>
  <span class="space"></span>
  <mat-list class="inline-list">
    <mat-list-item routerLink="list-client" routerLinkActive="active">Klienci</mat-list-item>
    <mat-list-item routerLink="list-categories" routerLinkActive="active">Kategorie</mat-list-item>
    <mat-list-item routerLink="list-products" routerLinkActive="active">Produkty</mat-list-item>
    <mat-list-item routerLink="list-shows" routerLinkActive="active">Pokazy</mat-list-item>
    <mat-list-item  (click)="logout()"><span class="material-icons mr-2">keyboard_tab</span>Wyloguj się</mat-list-item>
  </mat-list>
</mat-toolbar>
