import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderFrontEndComponent } from './components/header-front-end/header-front-end.component';
import {AppRoutingModule} from '../../app-routing.module';
import {MatRippleModule} from '@angular/material/core';
import { FooterComponent } from './components/footer/footer.component';
import { BasicMenuComponent } from './components/menu-footer-mobile/basic-menu/basic-menu.component';
import { ExtendedMenuComponent } from './components/menu-footer-mobile/extended-menu/extended-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
@NgModule({
  declarations: [
    HeaderFrontEndComponent,
    FooterComponent,
    BasicMenuComponent,
    ExtendedMenuComponent,
  ],
  exports: [
    HeaderFrontEndComponent,
    FooterComponent,
    BasicMenuComponent,
    ExtendedMenuComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MatRippleModule,
    MatButtonModule,
    MatBottomSheetModule
  ]
})
export class FrontEndSharedModule { }
