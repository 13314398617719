<div class="container-fluid pl-0 pr-0 pl-lg-5 pr-lg-5">
  <div class="row justify-content-center">
    <div class="col-12">
      <form #sendEmail="ngForm">

        <div class="row justify-content-center mb-4">
          <div class="col-12">
            <div class="text-center">
              <div class="h4 fw-normal d-none d-lg-block">Wysyłanie pokazu drogą e-mail</div>
              <div class="h6 fw-normal d-block d-lg-none">Wysyłanie pokazu drogą e-mail</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12">
            <mat-form-field appearance="fill" color="accent" class="w-100">
              <mat-label>Wprowadź adres E-mail</mat-label>
              <input name="email" matInput [(ngModel)]="keyAddressEmail">
            </mat-form-field>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <ul class="list-inline">
              <li class="list-inline-item">
                <button mat-raised-button color="accent" [disabled]="!keyAddressEmail" (click)="send(sendEmail)">Wyślij pokaz</button>
              </li>
              <li class="list-inline-item">
                <button mat-raised-button color="primary" (click)="close()">Zamknij</button>
              </li>
            </ul>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
