import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogErrorLoginComponent } from './dialog-error-login/dialog-error-login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogSuccessSaveEditComponent } from './dialog-success-save-edit/dialog-success-save-edit.component';
import { DialogDeleteItemComponent } from './dialog-delete-item/dialog-delete-item.component';
import { DialogCreateSuccessComponent } from './dialog-create-success/dialog-create-success.component';
import { DialogPreloaderComponent } from './dialog-preloader/dialog-preloader.component';
import { DialogSuccessUploadFileComponent } from './dialog-success-upload-file/dialog-success-upload-file.component';
import { DialogInfoIsEmptyListComponent } from './dialog-info-is-empty-list/dialog-info-is-empty-list.component';
import { DialogAddProductsTopShowsComponent } from './dialog-add-products-top-shows/dialog-add-products-top-shows.component';
import { MatCardModule } from '@angular/material/card';
import { DialogDownloadVideoComponent } from './dialog-download-video/dialog-download-video.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import { DialogProcessSendEmailComponent } from './dialog-process-send-email/dialog-process-send-email.component';
import { DialogSuccessSendEmailComponent } from './dialog-success-send-email/dialog-success-send-email.component';
import { DialogErrorComponent } from './dialog-error/dialog-error.component';
import { DialogProcessDownloadFileComponent } from './dialog-process-download-file/dialog-process-download-file.component';
import { DialogPopUpProductComponent } from './dialog-pop-up-product/dialog-pop-up-product.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { DialogThereAreNoProductsToDisplayComponent } from './dialog-there-are-no-products-to-display/dialog-there-are-no-products-to-display.component';
import { DialogSuccessAddToCartComponent } from './dialog-success-add-to-cart/dialog-success-add-to-cart.component';
import { DialogEmptyCacheComponent } from './dialog-empty-cache/dialog-empty-cache.component';
import { DialogRefreshCacheComponent } from './dialog-refresh-cache/dialog-refresh-cache.component';
import { DialogDownloadVideoClientComponent } from './dialog-download-video-client/dialog-download-video-client.component';
import { DialogRefreshPlayerWithParametrUrlComponent } from './dialog-refresh-player-with-parametr-url/dialog-refresh-player-with-parametr-url.component';

@NgModule({
  declarations: [
    DialogErrorLoginComponent,
    DialogSuccessSaveEditComponent,
    DialogDeleteItemComponent,
    DialogCreateSuccessComponent,
    DialogPreloaderComponent,
    DialogSuccessUploadFileComponent,
    DialogInfoIsEmptyListComponent,
    DialogAddProductsTopShowsComponent,
    DialogDownloadVideoComponent,
    DialogProcessSendEmailComponent,
    DialogSuccessSendEmailComponent,
    DialogErrorComponent,
    DialogProcessDownloadFileComponent,
    DialogPopUpProductComponent,
    DialogThereAreNoProductsToDisplayComponent,
    DialogSuccessAddToCartComponent,
    DialogEmptyCacheComponent,
    DialogRefreshCacheComponent,
    DialogDownloadVideoClientComponent,
    DialogRefreshPlayerWithParametrUrlComponent,
  ],
  exports: [
    DialogErrorLoginComponent,
    DialogSuccessSaveEditComponent,
    DialogDeleteItemComponent,
    DialogCreateSuccessComponent,
    DialogPreloaderComponent,
    DialogSuccessUploadFileComponent,
    DialogInfoIsEmptyListComponent,
    DialogAddProductsTopShowsComponent,
    DialogDownloadVideoComponent,
    DialogProcessSendEmailComponent,
    DialogSuccessSendEmailComponent,
    DialogErrorLoginComponent,
    DialogProcessDownloadFileComponent,
    DialogPopUpProductComponent,
    DialogThereAreNoProductsToDisplayComponent,
    DialogSuccessAddToCartComponent,
    DialogEmptyCacheComponent,
    DialogRefreshCacheComponent,
    DialogDownloadVideoClientComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    YouTubePlayerModule
  ]
})
export class DialogModule { }
