import {Component, OnInit} from '@angular/core';
import {BottomSheetService} from '../../../../global/bottom-sheet.service';

@Component({
  selector: 'app-header-front-end',
  templateUrl: './header-front-end.component.html',
  styleUrls: ['./header-front-end.component.css']
})
export class HeaderFrontEndComponent implements OnInit {

  constructor(private bottomSheetService: BottomSheetService) { }

  ngOnInit(): void {}

  onMenuMobile(): void {
    this.bottomSheetService.menuMobile();
  }

}
