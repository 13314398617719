import { Component, OnInit } from '@angular/core';
import {BackEndApiService} from '../../../service/back-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {IShows} from '../../../service/interface';

@Component({
  selector: 'app-add-shows',
  templateUrl: './add-shows.component.html',
  styleUrls: ['./add-shows.component.css']
})
export class AddShowsComponent implements OnInit {

  lastShowsID;
  keyShowName = '';

  constructor(
    private api: BackEndApiService,
    private global: GlobalService,
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  create(createShows: NgForm): void {
    console.log(createShows);
    const shows: IShows = {
      showName:  this.keyShowName
    };
    this.api.addShow(shows).subscribe(propsCreateShows => {
      this.global.dialogCreateSuccess();
      if (propsCreateShows) {
        this.api.getShows().subscribe(propsGetLastID => {
          const stringify = JSON.stringify(propsGetLastID.slice(-1).pop());
          const parser = JSON.parse(stringify);
          this.lastShowsID = parser.id;
          this.route.navigateByUrl('/dashboard/panel/edit-shows/' + this.lastShowsID);
        });
      }
    });
  }

}
