<div mat-dialog-title>
  <div class="container-xxl">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 align-self-center">
        <div class="h5 text-center text-lg-left mb-3 mb-lg-0">Szczegółowe informacje dotyczące produktu</div>
      </div>
      <div class="col-12 col-lg-6 align-self-center text-center text-lg-right">
        <ng-container *ngFor="let product of displayProduct">
          <ng-container *ngIf="product.videoYoutube && product.priceProduct && product.timeVideoAll">
            <button mat-raised-button class="text-uppercase" color="primary" *ngIf="hideButtonAddToCart" (click)="onAddToCart(product.id)">
              <span class="material-icons mr-2">add</span>Dodaj do pokazu
            </button>
          </ng-container>
          <ng-container *ngIf="!product.videoYoutube || !product.priceProduct || !product.timeVideoAll">
            <button mat-raised-button class="text-uppercase" color="primary" [disabled]="true" *ngIf="hideButtonAddToCart">
              <span class="material-icons mr-2">not_interested</span>Produkt niedostępny
            </button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<mat-dialog-content>
<div class="container-xxl">

  <div class="row justify-content-center" *ngIf="loader">
    <div class="col-12 mt-5 mb-5">
      <mat-spinner class="mx-auto d-block" [diameter]="40" color="accent"></mat-spinner>
    </div>
  </div>

  <ng-container *ngIf="!loader">
    <div class="row justify-content-center" *ngFor="let product of displayProduct">
      <div class="col-12 col-pop-up-product-mobile align-self-center" *ngIf="product.videoYoutube">
        <youtube-player
          videoId="{{product.videoYoutube}}"
          suggestedQuality="highres"
          [height]="465"
          [width]="'100%'"
          [startSeconds]="product.timeVideoStart"
          [endSeconds]="product.timeVideoEnd"
        ></youtube-player>
      </div>
      <div class="col-12 col-table-mobile">
        <table class="table table-dark table-striped h5 d-none d-lg-block">
          <thead>
          <tr class="text-center table-dark">
            <th scope="col" colspan="2">Dane</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Model:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.modelProduct">{{product.modelProduct}}</span>
              <span *ngIf="!product.modelProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Index:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.indexProduct">{{product.indexProduct}}</span>
              <span *ngIf="!product.indexProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Cena:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.priceProduct">{{product.priceProduct}}</span>
              <span *ngIf="!product.priceProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Liczba strzałów:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.shootsProduct">{{product.shootsProduct}}</span>
              <span *ngIf="!product.shootsProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Czas:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.timeVideoAll">{{product.timeVideoAll}} sek.</span>
              <span *ngIf="!product.timeVideoAll"> - </span>
            </td>
          </tr>
          <tr>
            <td>Kaliber:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.caliberProduct">{{product.caliberProduct}}</span>
              <span *ngIf="!product.caliberProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Wymiary:</td>
            <td class="fw-normal align-middle">
              <span *ngIf="product.dimensionsProduct">{{product.dimensionsProduct}}</span>
              <span *ngIf="!product.dimensionsProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td>Opis produktu:</td>
            <td class="break fw-normal align-middle">
              <span *ngIf="product.descriptionProduct">{{product.descriptionProduct}}</span>
              <span *ngIf="!product.descriptionProduct"> - </span>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- mobile -->
        <table class="table table-dark table-striped h5 d-block d-lg-none">
          <thead>
          <tr class="text-center table-dark">
            <th scope="col">Dane</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Model:</div>
              <span *ngIf="product.modelProduct">{{product.modelProduct}}</span>
              <span *ngIf="!product.modelProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Index:</div>
              <span *ngIf="product.indexProduct">{{product.indexProduct}}</span>
              <span *ngIf="!product.indexProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Cena:</div>
              <span *ngIf="product.priceProduct">{{product.priceProduct}}</span>
              <span *ngIf="!product.priceProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Liczba strzałów:</div>
              <span *ngIf="product.shootsProduct">{{product.shootsProduct}}</span>
              <span *ngIf="!product.shootsProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Czas:</div>
              <span *ngIf="product.timeVideoAll">{{product.timeVideoAll}} sek.</span>
              <span *ngIf="!product.timeVideoAll"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Kaliber:</div>
              <span *ngIf="product.caliberProduct">{{product.caliberProduct}}</span>
              <span *ngIf="!product.caliberProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Wymiary:</div>
              <span *ngIf="product.dimensionsProduct">{{product.dimensionsProduct}}</span>
              <span *ngIf="!product.dimensionsProduct"> - </span>
            </td>
          </tr>
          <tr>
            <td class="break fw-normal align-middle">
              <div class="h6 mb-0 text-white-50 fw-normal">Opis produktu:</div>
              <span *ngIf="product.descriptionProduct">{{product.descriptionProduct}}</span>
              <span *ngIf="!product.descriptionProduct"> - </span>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- mobile end -->
      </div>
    </div>
  </ng-container>

</div>
</mat-dialog-content>
<div mat-dialog-actions>
<button mat-raised-button class="mx-auto" [mat-dialog-close]="data.idProduct" cdkFocusInitial color="primary">Zamknij</button>
</div>
