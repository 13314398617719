import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {IShows} from '../../../service/interface';
import {GlobalService} from '../../../../global/global.service';
import {BackEndApiService} from '../../../service/back-end-api.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {DialogAddProductsTopShowsComponent} from '../../../../dialog/dialog-add-products-top-shows/dialog-add-products-top-shows.component';
import {MatDialog} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-edit-shows',
  templateUrl: './edit-shows.component.html',
  styleUrls: ['./edit-shows.component.css']
})
export class EditShowsComponent implements OnInit {

  itemDetails: Observable<Array<IShows>>;
  media = this.url.Media;

  products: string[] = [];
  allProductsFromDB: any;

  /** upload file */
  /** add module ReactiveFormsModule */
  /** add in constructor private httpUploadFile: HttpClient */
  imageSrc: any;
  myForm: any;

  constructor(
    private global: GlobalService,
    private api: BackEndApiService,
    private route: ActivatedRoute,
    private httpUploadFile: HttpClient,
    public dialog: MatDialog,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.itemDetails = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.api.getShow(params.get('id')))
    );
    this.itemDetails.subscribe(props => {
      if (props) {
        const stringifyItemDetails = JSON.stringify(props);
        const parseItemDetails = JSON.parse(stringifyItemDetails);
        this.products = parseItemDetails.showProducts;

        this.global.hideDialog();

        /** upload file */
        this.myForm = new FormGroup({
          currentID: new FormControl(parseItemDetails.id),
          file: new FormControl(''),
          fileSource: new FormControl('')
        });

      }
    });

    this.api.getProducts().subscribe(props => {
      this.allProductsFromDB = props;
    });

  }

  onSaveChanges(update: NgForm): void {
    const shows: IShows = {
      id: update.form.value.id,
      showName: update.form.value.nameCategory,
      showDescription: update.form.value.showDescription,
      showProducts: this.products,
      showIcon: update.form.value.showIcon,
      showTitle: update.form.value.showTitle
    };

    this.api.updateShow(shows).subscribe(props => {
      console.log(props);
      this.global.dialogSuccessSaveEdit();
    });

  }

  /** upload file start */
  get f(): void {
    return this.myForm.controls;
  }
  onFileChange(event: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.myForm.patchValue({
          fileSource: reader.result
        });
      };
    }
  }

  onSendFile(): void {
    /** upload file start */
    // tslint:disable-next-line:max-line-length
    this.httpUploadFile.post(this.url.Upload + 'shows', this.myForm.value).subscribe(props => {
      console.log(props);
      this.global.dialogSuccessUploadFile();
    });
  }

  openDialogProducts(): void {
    const dialogRef = this.dialog.open(DialogAddProductsTopShowsComponent, {
      data: {dialog_products: this.products}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.products = result;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.products, event.previousIndex, event.currentIndex);
  }

  delete(item: any): void {
    this.products.splice(item, 1);
  }


}
