import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from '../../app-routing.module';
import { FrontEndSharedModule } from '../shared/front-end-shared.module';
import { InstructionForUsingTheApplicationComponent } from './instruction-for-using-the-application/instruction-for-using-the-application.component';
import { MatDividerModule } from '@angular/material/divider';
import { HowChooseFireworksComponent } from './how-choose-fireworks/how-choose-fireworks.component';
import { SuggestionShowsComponent } from './suggestion-shows/suggestion-shows.component';
import { MatRippleModule } from '@angular/material/core';
import { SubpageSuggestionShowsComponent } from './subpage-suggestion-shows/subpage-suggestion-shows.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { IntroCreateClientShowsComponent } from './intro-create-client-shows/intro-create-client-shows.component';
import { SharedSuggestionShowsComponent } from './shared/shared-suggestion-shows/shared-suggestion-shows.component';
import { CreateYourVisualizationClientComponent } from './create-your-visualization-client/create-your-visualization-client.component';
import { SearchSimpleComponent } from './search/search-simple/search-simple.component';
import { SearchSimpleProductsComponent } from './search/search-simple-products/search-simple-products.component';
import { SearchAdvancedComponent } from './search/search-advanced/search-advanced.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RefreshPageComponent } from './refresh-page/refresh-page.component';
import { ClientComponent } from './client/client.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
@NgModule({
  declarations: [
    HomeComponent,
    InstructionForUsingTheApplicationComponent,
    HowChooseFireworksComponent,
    SuggestionShowsComponent,
    SubpageSuggestionShowsComponent,
    IntroCreateClientShowsComponent,
    SharedSuggestionShowsComponent,
    CreateYourVisualizationClientComponent,
    SearchSimpleComponent,
    SearchSimpleProductsComponent,
    SearchAdvancedComponent,
    RefreshPageComponent,
    ClientComponent
  ],
  exports: [
    HomeComponent,
    InstructionForUsingTheApplicationComponent,
    HowChooseFireworksComponent,
    SuggestionShowsComponent,
    SubpageSuggestionShowsComponent,
    IntroCreateClientShowsComponent,
    SharedSuggestionShowsComponent,
    CreateYourVisualizationClientComponent,
    SearchSimpleComponent,
    SearchSimpleProductsComponent,
    SearchAdvancedComponent,
    RefreshPageComponent,
    ClientComponent
  ],
  imports: [
    CommonModule,
    YouTubePlayerModule,
    AppRoutingModule,
    FrontEndSharedModule,
    MatDividerModule,
    MatRippleModule,
    DragDropModule,
    MatButtonModule,
    MatTooltipModule,
    NgxSliderModule
  ]
})
export class FrontPagesModule { }
