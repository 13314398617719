import { Component, OnInit } from '@angular/core';
import {FrontEndApiService} from '../../../service/front-end-api.service';
import {GlobalService} from '../../../../global/global.service';
import {switchMap} from 'rxjs/operators';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {ICategory} from '../../../service/interfaceFront';
import {MatDialog} from '@angular/material/dialog';
import {DialogPopUpProductComponent} from '../../../../dialog/dialog-pop-up-product/dialog-pop-up-product.component';
import {UrlService} from '../../../../global/url.service';

export interface DialogDataPopUpProduct {
  idProduct: string;
  showButtonAddToCart: boolean;
}

@Component({
  selector: 'app-search-simple-products',
  templateUrl: './search-simple-products.component.html',
  styleUrls: ['./search-simple-products.component.css']
})
export class SearchSimpleProductsComponent implements OnInit {
  categoryDetails: Observable<Array<ICategory>>;
  itemCategory = [];
  itemProducts = [];
  currentID;
  idProduct: string;
  media = this.url.Media;
  cacheTime: any[] = [];
  cacheDisplayTime;
  cacheProducts: any[] = [];
  cacheDisplayPrice;
  dialogButtonAddToCart;
  sumTime: number = null;
  sumPrice: number = null;
  constructor(
    private api: FrontEndApiService,
    private global: GlobalService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    if (JSON.parse(window.localStorage.getItem('product'))) {
      this.cacheProducts = JSON.parse(window.localStorage.getItem('product'));
    }
    if (window.localStorage.getItem('time')) {
      this.sumTime = parseInt(window.localStorage.getItem('time'), 10);
      this.cacheDisplayTime = this.global.secondsToTime(window.localStorage.getItem('time'));
    }
    if (window.localStorage.getItem('price')) {
      this.sumPrice = parseInt(window.localStorage.getItem('price'), 10);
      this.cacheDisplayPrice = parseInt(window.localStorage.getItem('price'), 10);
    }
    this.route.url.subscribe(props => {
      this.currentID = parseInt(props[1].path, 10);
    });

    this.global.dialogPreloader();
    this.categoryDetails = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.api.getCategory(params.get('id')))
    );
    this.categoryDetails.subscribe(props => {
      this.itemCategory.push(props);
    });
    this.api.getProducts().subscribe(propsProducts => {
      const stringifyProducts = JSON.stringify(propsProducts);
      const parseProducts = JSON.parse(stringifyProducts);
      this.global.loaderImage();

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < parseProducts.length; i++) {
        if (parseProducts[i].categoryProduct.length > 0) {
          if (parseProducts[i].categoryProduct.find(cat => cat === this.currentID)) {
            this.itemProducts.push(parseProducts[i]);
          }
        }
      }

      if (this.itemProducts.length > 0) {
        this.global.hideDialog();
      } else {
        this.global.dialogThereAreNoProductsToDisplay();
      }

    });

  }

  onPopUpProduct(id: any): void {
    const dialogRef = this.dialog.open(DialogPopUpProductComponent, {
      data: {idProduct: id, showButtonAddToCart: true}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.idProduct = result;
      if (result.addToCart !== undefined) {
        // from dialog pop up product
        this.onAddToCart(result.addToCart);
      }
    });
  }

  onAddToCart(id: any): void {
    let createProductsArray = [];
    this.api.getProduct(id).subscribe(props => {
      this.global.dialogSuccessAddToCart();
      this.cacheTime.push(props);
      createProductsArray = this.cacheTime.map((ele) => ({
        id: ele.id,
        timeAll: parseInt(ele.timeVideoAll, 10),
        youTube: ele.videoYoutube,
        start: ele.timeVideoStart,
        end: ele.timeVideoEnd,
        price: parseInt(ele.priceProduct, 10),
        shoots: ele.shootsProduct,
        icon: ele.iconProduct
      }));
      const arrayTime = [];
      createProductsArray.forEach(time => {
        arrayTime.push(time.timeAll);
      });
      let sumTime: number = null;
      arrayTime.forEach((value) => {
        sumTime += value;
      });
      const arrayPrice = [];
      createProductsArray.forEach(priceProducts => {
        arrayPrice.push(priceProducts.price);
      });
      let sumPrice: number = null;
      arrayPrice.forEach((value) => {
        sumPrice += value;
      });
      const resultTime = this.sumTime + sumTime;
      const resultPrice = this.sumPrice + sumPrice;
      localStorage.setItem('time', JSON.stringify(resultTime));
      localStorage.setItem('price', JSON.stringify(resultPrice));
      this.cacheDisplayTime = this.global.secondsToTime(window.localStorage.getItem('time'));
      this.cacheDisplayPrice = parseInt(window.localStorage.getItem('price'), 10);

      if (JSON.parse(window.localStorage.getItem('product'))) {
        this.cacheProducts = JSON.parse(window.localStorage.getItem('product'));
      }
      this.cacheProducts.push(id);
      localStorage.setItem('product', JSON.stringify(this.cacheProducts));
    });
  }

}
