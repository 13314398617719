<app-header-front-end></app-header-front-end>
<div class="container-xxl container-banner mt-0 mt-lg-3">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="banner" id="banner"></div>
      <!--<img alt="Piromax - Producent petard i fajerwerków" src="assets/img/front/banner.jpg" class="img-fluid mx-auto d-block"/>-->
    </div>
  </div>
</div>
<div class="container-xxl text-center text-uppercase mt-3">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-4 mb-2 mb-lg-0">
      <div class="card card-default h-100" routerLink="/instrukcja-korzystania-z-aplikacji" mat-ripple>
        <div class="card-body">
          <div class="card-title h3 mb-0 pt-1 pb-1 pt-lg-3 pb-lg-3 fw-normal">
            <div class="d-none d-lg-block">Instrukcja <br/> korzystania <br/> z aplikacji</div>
            <!-- mobile -->
            <div class="d-block d-lg-none title-instructions-for-using-the-application">Instrukcja korzystania z aplikacji</div>
            <!-- mobile end -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-2 mb-lg-0 order-1 order-lg-0">
      <div class="card card-red h-100" routerLink="/tworzenie-pokazu" mat-ripple>
        <div class="card-body">
          <div class="card-title h3 mb-0 pt-1 pb-1 pt-lg-3 pb-lg-3 fw-normal">
            <div class="d-none d-lg-block">Stwórz <br/> własny pokaz <br/> pirotechniczny</div>
            <!-- mobile -->
            <div class="d-block d-lg-none title-instructions-for-using-the-application">Stwórz własny pokaz pirotechniczny</div>
            <!-- mobile end -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4 mb-2 mb-lg-0">
      <div class="card card-default h-100" routerLink="/jak-wybrac-fajerwerki" mat-ripple>
        <div class="card-body">
          <div class="card-title h3 mb-0 pt-1 pb-1 pt-lg-3 pb-lg-3 fw-normal">
            <div class="d-none d-lg-block">Jak <br/> wybrać <br/> fajerwerki ?</div>
            <!-- mobile -->
            <div class="d-block d-lg-none title-instructions-for-using-the-application">Jak wybrać fajerwerki ?</div>
            <!-- mobile end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<app-basic-menu></app-basic-menu>
