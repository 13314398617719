import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {IClient} from '../../../service/interface';
import {GlobalService} from '../../../../global/global.service';
import {BackEndApiService} from '../../../service/back-end-api.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {UrlService} from '../../../../global/url.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  itemDetails: Observable<Array<IClient>>;
  media = this.url.Media;
  clientProducts = [];

  constructor(
    private global: GlobalService,
    private api: BackEndApiService,
    private route: ActivatedRoute,
    private url: UrlService
  ) { }

  ngOnInit(): void {
    this.global.dialogPreloader();
    this.itemDetails = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.api.getClient(params.get('id')))
    );
    this.itemDetails.subscribe(propsClient => {
      this.global.hideDialog();
      const stringify = JSON.stringify(propsClient);
      const parse = JSON.parse(stringify);

      this.api.getProducts().subscribe(propsProduct => {
        parse.playlist.forEach((indexClient) => {
          propsProduct.forEach(indexProducts => {
            if (indexProducts.id === indexClient) {
              this.clientProducts.push(indexProducts);
            }
          });
        });
      });

    });
  }

}
