import { Component, OnInit } from '@angular/core';
import { BackEndApiService } from '../../../service/back-end-api.service';
import { GlobalService } from '../../../../global/global.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ICategory } from '../../../service/interface';

@Component({
  selector: 'app-add-categories',
  templateUrl: './add-categories.component.html',
  styleUrls: ['./add-categories.component.css']
})
export class AddCategoriesComponent implements OnInit {

  lastCategoryID;
  keyNameCategory = '';
  keyIconCategory = '';

  constructor(
    private api: BackEndApiService,
    private global: GlobalService,
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  create(createCategory: NgForm): void {
    const category: ICategory = {
      nameCategory: this.keyNameCategory,
      iconCategory: this.keyIconCategory
    };
    this.api.addCategory(category).subscribe(createCategoryProps => {
      this.global.dialogCreateSuccess();
      if (createCategoryProps) {
        this.api.getCategories().subscribe(getLastCategoryIDProps => {
          const stringify = JSON.stringify(getLastCategoryIDProps.slice(-1).pop());
          const parse = JSON.parse(stringify);
          this.lastCategoryID = parse.id;
          this.route.navigateByUrl('/dashboard/panel/edit-categories/' + this.lastCategoryID);
        });
      }
    });
  }

}
