<div class="container-fluid mt-3">
  <div class="row justify-content-center">
    <div class="col-12 align-self-center">
      <h4 class="mb-0">Tworzenie produktu</h4>
    </div>
  </div>
</div>
<div class="container-fluid mt-3 mb-3">
  <div class="row justify-content-center">
    <div class="col-12">
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="row justify-content-center">
        <form #createProduct="ngForm">
          <div class="col-12">
            <mat-form-field appearance="fill" color="accent" class="w-100">
              <mat-label>Index produktu ( np. Alternative )</mat-label>
              <input name="indexProduct" matInput [(ngModel)]="keyIndexProduct">
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" color="accent" class="w-100">
              <mat-label>Model produktu ( np. PXC201 )</mat-label>
              <input name="modelProduct" matInput [(ngModel)]="keyModelProduct">
              <mat-icon matSuffix>priority_high</mat-icon>
              <mat-hint>Info: Pole wymagane</mat-hint>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12">
      <button [disabled]="!keyModelProduct" mat-raised-button class="btn-success rounded-0 mx-auto d-block" (click)="create(createProduct)">Utwórz</button>
    </div>
  </div>
</div>
