import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-process-download-file',
  templateUrl: './dialog-process-download-file.component.html',
  styleUrls: ['./dialog-process-download-file.component.css']
})
export class DialogProcessDownloadFileComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialog.closeAll();
  }

}
