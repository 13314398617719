<mat-nav-list>
  <mat-list-item disableRipple>
    <div matLine class="text-white-50">Klienci</div>
    <button
      routerLink="list-client"
      mat-icon-button class="text-white-50"
      matTooltip="Lista pokazów wysłanych drogą e-mail"
      matTooltipPosition="right"
      aria-label="Lista pokazów klientów">
      <span class="material-icons">view_headline</span>
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item disableRipple>
    <div matLine class="text-white-50">Produkty</div>
    <button
      routerLink="list-products"
      mat-icon-button class="text-white-50"
      matTooltip="Lista produktów"
      matTooltipPosition="right"
      aria-label="Lista produktów">
      <span class="material-icons">view_headline</span>
    </button>
    <button
      routerLink="add-products"
      mat-icon-button class="text-white-50"
      matTooltip="Dodaj produkt"
      matTooltipPosition="right"
      aria-label="Dodaj produkt">
      <span class="material-icons">add_circle_outline</span>
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item disableRipple>
    <div matLine class="text-white-50">Kategorie</div>
    <button
      routerLink="list-categories"
      mat-icon-button class="text-white-50"
      matTooltip="Lista kategorii"
      matTooltipPosition="right"
      aria-label="Lista kategorii">
      <span class="material-icons">view_headline</span>
    </button>
    <button
      routerLink="add-categories"
      mat-icon-button class="text-white-50"
      matTooltip="Dodaj kategorie"
      matTooltipPosition="right"
      aria-label="Dodaj kategorie">
      <span class="material-icons">add_circle_outline</span>
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item disableRipple>
    <div matLine class="text-white-50">Pokazy</div>
    <button
      routerLink="list-shows"
      mat-icon-button class="text-white-50"
      matTooltip="Lista pokazów"
      matTooltipPosition="right"
      aria-label="Lista pokazów">
      <span class="material-icons">view_headline</span>
    </button>
    <button
      routerLink="add-shows"
      mat-icon-button class="text-white-50"
      matTooltip="Dodaj pokaz"
      matTooltipPosition="right"
      aria-label="Dodaj pokaz">
      <span class="material-icons">add_circle_outline</span>
    </button>
  </mat-list-item>
</mat-nav-list>
