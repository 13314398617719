import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ICategory, IProducts, IShows, IEmail, IClient} from './interfaceFront';
import { HttpClient } from '@angular/common/http';
import {UrlService} from '../../global/url.service';

@Injectable({
  providedIn: 'root'
})
export class FrontEndApiService {
  constructor(
    private http: HttpClient,
    private url: UrlService
  ) { }
  getShows(): Observable<Array<IShows>> {
    return this.http.get<Array<IShows>>(this.url.Api + 'shows.json');
  }
  getShow(id: string | number): Observable<Array<IShows>> {
    return this.http.get<Array<IShows>>(this.url.Api + 'shows/' + id + '.json');
  }
  getProducts(): Observable<Array<IProducts>> {
    return this.http.get<Array<IProducts>>(this.url.Api + 'products.json');
  }
  getProduct(id: string | number): Observable<Array<IProducts>> {
    return this.http.get<Array<IProducts>>(this.url.Api + 'products/' + id + '.json');
  }
  getCategories(): Observable<Array<ICategory>> {
    return this.http.get<Array<ICategory>>(this.url.Api + 'categories.json');
  }
  getCategory(id: string | number): Observable<Array<ICategory>> {
    return this.http.get<Array<ICategory>>(this.url.Api + 'categories/' + id + '.json');
  }
  sendDownloadVideo(data: IEmail): Observable<any> {
    return this.http.post<any>(this.url.Email, data);
  }
  addClient(client: IClient): any {
    return this.http.post<Array<IClient>>(this.url.Api + 'clients.json', client);
  }
  getClient(slug: string | number): Observable<Array<IClient>> {
    return this.http.get<Array<IClient>>(this.url.Api + 'clients?client=' + slug);
  }
}
