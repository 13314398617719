<div class="container-fluid container-scroll">
  <div class="row justify-content-center">
    <ng-container *ngFor="let product of displayProducts">
      <div class="col-2 text-center mb-3">
        <mat-card class="bg-dark text-center h-100">
          <div>{{product.modelProduct}}</div>

          <ng-container *ngIf="!product.videoYoutube">
            <div class="alert alert-danger p-1">
              Brak załączonego video
            </div>
          </ng-container>

          <ng-container *ngIf="product.iconProduct">
            <div class="backgroundImage mt-2 mx-auto" [ngStyle]="{'background-image': 'url(' + media + product.iconProduct + ')' }"></div>
          </ng-container>
          <ng-container *ngIf="!product.iconProduct">
            <span class="material-icons display-2 mb-0 text-white-50 mx-auto">image_not_supported</span>
          </ng-container>

          <button (click)="addProducts(product.id)" mat-raised-button color="accent">Dodaj</button>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>

<div mat-dialog-actions>
  <button class="mx-auto" mat-raised-button color="primary" [mat-dialog-close]="data.dialog_products" cdkFocusInitial>Ok</button>
</div>
