<app-header-front-end></app-header-front-end>
<div class="container-xxl mt-0 mt-lg-3">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="d-none d-lg-block">
        <div class="display-6 mb-0 text-uppercase text-center fw-normal">Wyszukiwanie Proste</div>
      </div>
      <!-- mobile -->
      <div class="d-block d-lg-none">
        <ul class="list-inline text-center">
          <li class="list-inline-item">
            <div class="h3 mb-0 text-uppercase text-center title-add-another-launcher fw-normal">
              <button mat-raised-button color="accent" class="pl-2 pr-2" routerLink="/wizualizacja-twojego-pokazu">
                <span class="material-icons icon-return-to-show-mobile">keyboard_backspace</span>Wróć do pokazu
              </button>
            </div>
          </li>
          <li class="list-inline-item ml-3">
            <div class="h3 mb-0 text-uppercase text-center title-add-another-launcher fw-normal">Dodaj kolejną wyrzutnię</div>
          </li>
        </ul>
      </div>
      <!-- mobile end -->
    </div>
  </div>
</div>
<!-- mobile -->
<div class="container-xxl d-block d-lg-none">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-filter-advanced mt-2 mb-2" mat-ripple routerLink="/wyszukiwanie-zaawansowane">
        <div class="card-body p-0 text-center text-uppercase">
          <div class="card-title pt-1">Filtry zaawansowane</div>
          <div class="card-text mb-0">Rozwiń</div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- mobile end -->
<div class="container-xxl mt-3">
  <div class="row justify-content-center">
    <ng-container *ngFor="let cat of categories">
      <div class="col-4 col-lg-3 mb-2 pl-1 pr-1">
        <div class="card card-mobile card-simple-search text-center h-100" mat-ripple routerLink="{{cat.id}}">
          <div class="card-body">
            <div class="d-none d-lg-block">
              <div class="card-title break h4 fw-normal">{{cat.nameCategory}}</div>
            </div>
            <!-- mobile -->
            <div class="d-block d-lg-none">
              <div class="card-title mb-0 break h4 title-category fw-normal">{{cat.nameCategory}}</div>
            </div>
            <!-- mobile end -->
          </div>
          <div class="card-footer border-0">
            <div
              class="backgroundImageCategorySearch loader-image-background"
              [attr.data-background-image]="media + cat.iconCategory"
            ></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<app-footer></app-footer>
<app-basic-menu></app-basic-menu>
