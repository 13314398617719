<div class="container-xxl">
  <div class="row justify-content-center">
    <div class="col-12">
      <ul class="nav flex-column">
        <li class="nav-item">
          <button class="nav-link w-100 btn btn-dark rounded-0 border-0" (click)="link1()">Instrukcja korzystania z aplikacji</button>
        </li>
        <li class="nav-item">
          <button class="nav-link w-100 btn btn-dark rounded-0 border-0" (click)="link2()">Jak wybrać fajerwerki</button>
        </li>
        <li class="nav-item">
          <button class="nav-link w-100 btn btn-dark rounded-0 border-0" (click)="link4()">Nowy pokaz</button>
        </li>
        <li class="nav-item">
          <button class="nav-link w-100 btn btn-dark rounded-0 border-0" (click)="link5()">Propozycje Piromax</button>
        </li>
      </ul>
    </div>
  </div>
</div>
