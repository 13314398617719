import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  /** localhost */
  // public Media = 'http://localhost/domeny/application-piromax/media/';
  // public Api = 'https://127.0.0.1:8000/api/';
  // public Email = 'http://localhost/domeny/application-piromax/email/email.php';
  // public Upload = 'http://localhost/domeny/application-piromax/media/upload.php?url=';

  /** serve */
  public Media = 'https://kreator.piromax.pl/media/';
  public Api = 'https://kreator.piromax.pl/api/public/api/';
  public Email = 'https://kreator.piromax.pl/email/email.php';
  public Upload = 'https://kreator.piromax.pl/media/upload.php?url=';

  constructor() { }
}
