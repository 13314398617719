import {Component, Inject, OnInit} from '@angular/core';
import {FrontEndApiService} from '../../front/service/front-end-api.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {IClient, IEmail} from '../../front/service/interfaceFront';
import {DialogProcessSendEmailComponent} from '../dialog-process-send-email/dialog-process-send-email.component';
import {DialogSuccessSendEmailComponent} from '../dialog-success-send-email/dialog-success-send-email.component';
import {DialogErrorComponent} from '../dialog-error/dialog-error.component';
import {
  CreateYourVisualizationClientComponent,
  DialogClientShows
} from '../../front/pages/create-your-visualization-client/create-your-visualization-client.component';

@Component({
  selector: 'app-dialog-download-video-client',
  templateUrl: './dialog-download-video-client.component.html',
  styleUrls: ['./dialog-download-video-client.component.css']
})
export class DialogDownloadVideoClientComponent implements OnInit {

  keyAddressEmail = '';
  currentSlug: string;
  product = [];
  currentData;

  constructor(
    public dialogRefClient: MatDialogRef<CreateYourVisualizationClientComponent>,
    private api: FrontEndApiService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogClientShows
  ) { }

  ngOnInit(): void {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    this.currentSlug = mm + '-' + dd + '-' + yyyy + '-' + hours + '-' + minutes + '-' + seconds;
    this.product = this.data.cacheDialog;
    this.currentData = 'Dzień: ' + dd + ' / ' + mm + ' / ' + yyyy + ' Godzina: ' + hours + ':' + minutes + ':' + seconds;
  }

  send(sendEmail: NgForm): void {
    const email: IEmail = {
      email: sendEmail.form.value.email,
      message: window.location.origin + '/pokaz/' + this.currentSlug,
      price: this.data.cachePrice,
      time: this.data.cacheTime
    };
    const client: IClient = {
      email: sendEmail.form.value.email,
      slug: window.location.origin + '/pokaz/' + this.currentSlug,
      playlist: this.product,
      client: this.currentSlug,
      currentData: this.currentData,
      Price: this.data.cachePrice.toString(),
      Time: this.data.cacheTime.toString()
    };
    this.api.addClient(client).subscribe(props => {
      console.log(props);
    });
    this.dialog.open(DialogProcessSendEmailComponent);
    this.api.sendDownloadVideo(email).subscribe(
      props => {
        console.log(props);
        this.dialog.open(DialogSuccessSendEmailComponent);
      }, error => {
        console.log(error);
        this.dialog.open(DialogErrorComponent);
      }
    );
  }

  close(): void {
    this.dialog.closeAll();
  }

}
