import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from '../../app-routing.module';
import { BackEndSharedModule } from '../shared/back-end-shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { AddCategoriesComponent } from './categories/add-categories/add-categories.component';
import { ListCategoriesComponent } from './categories/list-categories/list-categories.component';
import { EditCategoriesComponent } from './categories/edit-categories/edit-categories.component';
import { AddShowsComponent } from './create-shows/add-shows/add-shows.component';
import { ListShowsComponent } from './create-shows/list-shows/list-shows.component';
import { EditShowsComponent } from './create-shows/edit-shows/edit-shows.component';
import { AddProductsComponent } from './product/add-products/add-products.component';
import { EditProductsComponent } from './product/edit-products/edit-products.component';
import { ListProductsComponent } from './product/list-products/list-products.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { EditClientComponent } from './clients/edit-client/edit-client.component';
import { ListClientsComponent } from './clients/list-clients/list-clients.component';

@NgModule({
  declarations: [
    LoginComponent,
    DashboardComponent,
    AddCategoriesComponent,
    ListCategoriesComponent,
    EditCategoriesComponent,
    AddShowsComponent,
    ListShowsComponent,
    EditShowsComponent,
    AddProductsComponent,
    EditProductsComponent,
    ListProductsComponent,
    EditClientComponent,
    ListClientsComponent,
  ],
  exports: [
    LoginComponent,
    DashboardComponent,
    AddCategoriesComponent,
    ListCategoriesComponent,
    EditCategoriesComponent,
    AddShowsComponent,
    ListShowsComponent,
    EditShowsComponent,
    AddProductsComponent,
    EditProductsComponent,
    ListProductsComponent,
    EditClientComponent,
    ListClientsComponent
  ],
    imports: [
        CommonModule,
        AppRoutingModule,
        BackEndSharedModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSidenavModule,
        MatTableModule,
        MatPaginatorModule,
        MatDividerModule,
        MatCardModule,
        MatSelectModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        DragDropModule
    ],
})
export class BackEndPagesModule { }
